import React, { Component } from 'react';

class Home extends Component {

    render() {
        return (
            <div>
                <div className="jumbotron main-teaser">
                    <div className="container no-padding-top">
                        <h1 className="display-3">Just software, nothing else</h1>
                        <p>Building Apps & Games</p>
                    </div>
                </div>
                <br/><br/>
                <div className="container">

                    <h2 className="main-teaser-title">News</h2><br/>

                    <div className="row container">

                        <ul className="list-group list-group-news">
                            <li>
                                <span className="list-group-item-date">10.11.2019</span>
                                <h5>Reswipes v.1.2 (iOS)</h5>
                                <p className="list-group-item-text">Kleinere Anpassungen für iOS 13. Ab sofort im App Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">10.11.2019</span>
                                <h5>Jumble Immo v.1.2 (iOS)</h5>
                                <p className="list-group-item-text">Kleinere Anpassungen für iOS 13. Ab sofort im App Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">10.11.2019</span>
                                <h5>Jumble Jobs v.1.3 (iOS)</h5>
                                <p className="list-group-item-text">Kleinere Anpassungen für iOS 13. Ab sofort im App Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">06.10.2019</span>
                                <h5>Sticky Notes AR v.2.2 (iOS)</h5>
                                <p className="list-group-item-text">Kleinere Anpassungen für iOS 13. Ab sofort im App Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">23.09.2019</span>
                                <h5>Sticky Notes AR v.2.1 (iOS)</h5>
                                <p className="list-group-item-text">Kleinere Anpassungen für iOS 13. Ab sofort im App Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">20.08.2019</span>
                                <h5>Sticky Notes AR v.2.0 (iOS)</h5>
                                <p className="list-group-item-text">Der einzigartige Augmented Reality Messenger nun
                                    mit Apple Watch Unterstützung und noch besserer Augmented Reality Erfahrung,
                                    dank Markerless World Tracking. Ab sofort im App Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">18.07.2019</span>
                                <h5>Grocery List AR v.1.0 (Android)</h5>
                                <p className="list-group-item-text">Der einzigartige Augmented Reality (AR) Rezepte Scanner ab
                                    sofort auch im Google Play Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">13.07.2019</span>
                                <h5>Grocery List AR v.1.2</h5>
                                <p className="list-group-item-text">Der einzigartige Augmented Reality Rezepte Scanner,
                                    scannt nun auch Mengen und Maßeinheiten in zwei Sprachen.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">02.07.2019</span>
                                <h5>Sticky Notes AR v.1.0 (Android)</h5>
                                <p className="list-group-item-text">Der einzigartige Augmented Reality (AR) Messenger ab
                                    sofort auch im Google Play Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">10.10.2018</span>
                                <h5>Grocery List AR v.1.1</h5>
                                <p className="list-group-item-text">Der einzigartige Augmented Reality Rezepte Scanner nun
                                    auch auf der Apple Watch, ab sofort im App
                                    Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">08.10.2018</span>
                                <h5>Pickup Notifier for Apple Store v.1.0</h5>
                                <p className="list-group-item-text">Apple Store Pickup Notifications auf der Apple Watch, ab
                                    sofort im App
                                    Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">24.10.2018</span>
                                <h5>Nine Watch v.1.0</h5>
                                <p className="list-group-item-text">9GAG auf der Apple Watch, ab sofort im App
                                    Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">07.01.2018</span>
                                <h5>Grocery List AR v.1.0</h5>
                                <p className="list-group-item-text">Der einzigartige Augmented Reality Rezepte Scanner, ab
                                    sofort im App
                                    Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">23.12.2017</span>
                                <h5>Jumble Immo v.1.0.1</h5>
                                <p className="list-group-item-text">Die Metasuche Jumble nun auch für Immobilien, ab sofort
                                    im App
                                    Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">27.12.2017</span>
                                <h5>Reswipes v.1.1</h5>
                                <p className="list-group-item-text">Die swipetastische Rezepte-App, ab sofort im App
                                    Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">04.03.2017</span>
                                <h5>StickyNotes AR v.1.0</h5>
                                <p className="list-group-item-text">Der einzigartige Augmented Reality (AR) Messenger ab
                                    sofort im
                                    App Store.</p>
                                <hr/>
                            </li>
                            <li>
                                <span className="list-group-item-date">13.10.2016</span>
                                <h5>Jumble Jobs App v.1.1</h5>
                                <p>Die Jumble Jobs App bietet neue Features und arbeitet jetzt noch besser!</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
