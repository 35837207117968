import React, {Component} from 'react';

const countries = require('../data/country.json')
const rarities = require('../data/rarity.json')
const species = require('../data/species.json')

class CardanoTrees extends Component {

    constructor(props) {
        super(props);

        this.cnftMap = new Map()
        this.idList = Array.from(Array(10000).keys()).map((elem) => this.formatIndex(elem))
        this.setIndex(0)

        this.state = {
            index: 1,
            inputValue: "",
            metadata: "",
            isPurchasable: false,
            price: "",
            cnftId: "",
            filterPurchasable: false,
            filterPurchasableEnabled: false,
            purchasableIndex: 0,
            cnftCount: 0,
            totalFound: 10000
        };
    }

    componentDidMount() {
        this.loadCnftData()
    }

    handleInputChange(e) {
        const name = e.target.name
        let filterList = Array.from(Array(10000).keys()).map((elem) => this.formatIndex(elem))

        const inputs = document.getElementsByTagName("input")
        let filterActive = []
        for (var el of inputs) {
            if(el.type == "checkbox" && el.checked) {
                const name = el.name
                filterActive.push(name)
                switch(name) {
                    case "Alpha":
                    case "Beta":
                    case "Gamma":
                    case "Delta":
                    case "Epsilon":
                        const specyIds = species[name]
                        filterList = filterList.filter((elem) => specyIds.includes(elem))
                        break;
                    case "Common":
                    case "Uncommon":
                    case "Rare":
                    case "SuperRare":
                    case "EpicRare":
                        const rarityIds = rarities[name]
                        filterList = filterList.filter((elem) => rarityIds.includes(elem))
                        break;
                    case "CNFT.IO":
                        filterList = filterList.filter((elem) => this.cnftMap.has(elem))
                        break;
                }
            }
        }

        if (!(filterActive.length == 1 && filterActive[0] == "CNFT.IO")) {
            filterList.sort()
        }
        this.idList = Array.from(new Set(filterList))

        this.setState({
            totalFound: this.idList.length,
            inputValue: this.idList.length > 0 ? this.idList[0] : 0,
            url: this.idList.length > 0 ? this.state.url : null
        })

        if (this.idList.length > 0) {
            this.setIndex(0)
        }
    }

    async loadCnftData() {
        this.setState({
            filterPurchasableEnabled: false
        })
        this.cnftMap.clear()
        const fetchSize = 100
        const data = await this.postData('https://api.cnft.io/market/listings', {
            project: "CardanoTrees",
            sort: "date",
            order: "desc",
            count: fetchSize.toString(),
            page: 1,
            verified: true
        })

        for (var asset of data.assets) {
            const id = asset.metadata.name.split(" ")[1]
            this.cnftMap.set(id, {
                 cnftId: asset.id,
                 id,
                 price: asset.price
            })
        }

        const resultsCount = data.found
        if (resultsCount > fetchSize) {
            const lastPage = Math.ceil(resultsCount / fetchSize)
            for (var page = 2; page <= lastPage; page++) {
                const data = await this.postData('https://api.cnft.io/market/listings', {
                    project: "CardanoTrees",
                    sort: "date",
                    order: "desc",
                    count: fetchSize.toString(),
                    page: page,
                    verified: true
                })
                for (var asset of data.assets) {
                    const id = asset.metadata.name.split(" ")[1]
                    this.cnftMap.set(id, {
                         cnftId: asset.id,
                         id: id,
                         price: asset.price
                    })
                }
                this.setState({
                    cnftCount: this.cnftMap.size
                })
            }
        }

        this.setState({
            filterPurchasableEnabled: true
        })
    }

    async postData(url = '', data = {}, timeout = 1000) {
      const controller = new AbortController()
      const id = setTimeout(() => controller.abort(), timeout)
      const response = await fetch(url, {
        retries: 3,
        retryDelay: 1000,
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
//        signal: controller.signal
      })
      clearTimeout(id)
      return response.json()
    }

    increaseIndex() {
        if (this.state.index < this.state.resultsCount) {
            this.setIndex(this.state.index + 1)
        }
    }

    decreaseIndex(e) {
        if (this.state.index > 0) {
            this.setIndex(this.state.index - 1)
        }
    }

    setIndex(index, indexIsTreeId = false) {
        const treeId = indexIsTreeId ? index : this.idList[index]
        const counter = indexIsTreeId ? this.idList.indexOf(this.formatIndex(index)) : index
        fetch("https://pool.pm/asset/e09e4f4217669b7f735b7a3724e835d8d6344db128eb03d6ea72885e.CT" + this.formatIndex(treeId))
           .then(data => data.json())
           .then(json => {
                const cnft = this.cnftMap.get(treeId)
                this.setState({
                    index: counter,
                    url: json.metadata.files[0].src.join(""),
                    metadata: json.metadata,
                    isPurchasable: cnft != null,
                    price: cnft != null ? cnft.price : "",
                    cnftId: cnft != null ? cnft.cnftId : "",
                    resultsCount: this.idList.length,
                    inputValue: counter + 1
                })
           })
           .catch((err) => { console.log(err) })
    }

    getUrl(index) {
        return "https://pool.pm/e09e4f4217669b7f735b7a3724e835d8d6344db128eb03d6ea72885e.CT" + this.formatIndex(index)
    }

    formatIndex(index) {
        if (index == null) {
            return ""
        }
        const len = index.toString().length
        const leadingZeros = 4 - len
        let formattedIndex = ""
        for (var i = 0; i < leadingZeros; i++) {
            formattedIndex += "0"
        }
        return formattedIndex + index
    }

    updateInput(e) {
        this.setState({
            inputValue: e.target.value
        })
    }

    onFormSubmit(e) {
        e.preventDefault()
        const formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData);
        if (formProps.index) {
            this.setIndex(formProps.index - 1)
        }
        else {
            this.setIndex(formProps.treeId, true)
        }
    }

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render() {
        return (
            <div className="content cardanotrees">
               <div className="container">
                  <div className="container half-padding-top">
                     <h2 className="main-teaser-title">CardanoTrees #{this.formatIndex(this.idList[this.state.index])}</h2>
                     <br/>
                  </div>
                  <div className="container row">
                     <div className="col-md-2.5">
                        <div className="container">
                         <h4>Marketplaces</h4>
                        <ul className="filters">
                            <li>
                                <label>CNFT.IO&nbsp;<input name="CNFT.IO" disabled={!this.state.filterPurchasableEnabled} id="purchasable-check" type="checkbox" onChange={this.handleInputChange.bind(this)} /></label>
                                                                &nbsp;({this.state.cnftCount})&nbsp;{this.state.filterPurchasableEnabled && (<img src="../../img/update.svg" onClick={this.loadCnftData.bind(this)}/>)}
                            </li>
                        </ul>
                        <h4>TreeSpecies</h4>
                        <ul className="filters">
                            {
                                ["Alpha", "Beta", "Gamma", "Delta", "Epsilon"].map(key => {
                                      return (
                                        <li key={key}>
                                          <label>{key}&nbsp;<input name={key} type="checkbox" onChange={this.handleInputChange.bind(this)} /></label>
                                                                          &nbsp;({species[key].length})
                                      </li>
                                      )
                                 })
                            }
                        </ul>
                        <h4>Abundance</h4>
                        <ul className="filters">
                            {
                                ["Common", "Uncommon", "Rare", "SuperRare", "EpicRare"].map(key => {
                                      return (
                                        <li key={key}>
                                          <label>{key}&nbsp;<input name={key} type="checkbox" onChange={this.handleInputChange.bind(this)} /></label>
                                                                          &nbsp;({rarities[key].length})
                                      </li>
                                      )
                                 })
                            }
                        </ul>
                     </div>
                     </div>
                     <div className="col-md-6">
                        <div className="treeview-navigation">
                           <button id="next" style={{width: "190px"}} onClick={this.decreaseIndex.bind(this)} className="prev-button" type="button">Previous</button>
                            <form onSubmit={this.onFormSubmit.bind(this)} style={{display: "inline-block"}}>
                               <div style={{display: "flex", border: "1px solid black"}}>
                               <input
                                  className="input"
                                  type="text"
                                  name="index"
                                  value={this.state.inputValue}
                                  onChange={this.updateInput.bind(this)}
                                  id="query"
                                  autoComplete="off"/>
                               <div style={{backgroundColor: "white", width: "64px"}}><strong>&nbsp;/ {this.state.totalFound}</strong></div>
                               </div>
                            </form>
                           <button id="prev" style={{width: "190px"}} onClick={this.increaseIndex.bind(this)} className="next-button" type="button">Next</button>
                            <iframe src={this.state.url} width="512" height="512"></iframe>
                                                </div>
                     </div>
                     <div className="col-md-3">
                        <form onSubmit={this.onFormSubmit.bind(this)}>
                            <div style={{width: "300px"}}>
                           <input className="u-full-width search-input"
                              style={{width: "100px"}}
                              type="text"
                              name="treeId"
                              placeholder={"Tree id #"}
                              id="query"
                              autoComplete="off"/>
                           <button id="prev" type="submit">Jump to</button>
                           </div>
                        </form>
                        <div className="container v-spacer">
                                                <h4>Metadata</h4>
                           <table className="pool-stats-table">
                              <tbody className="cardanotree-table-body">
                                   {
                                        ["name",
                                         "TreeSpecies",
                                         "Abundance",
                                         "Country",
                                         "Environment",
                                         "Flowers",
                                         "Fruits",
                                         "Latitude",
                                         "Longitude",
                                         "NumberOfTrees"].map((name) => {
                                        const value = this.state.metadata[name]
                                        if (value != null)
                                        {
                                            if (name == "Country" || name == "Abundance" || name == "TreeSpecies") {
                                                let count = 0
                                                switch (name) {
                                                    case "Country":
                                                        count = countries[value].length
                                                    break;
                                                    case "Abundance":
                                                        count = rarities[value].length
                                                    break;
                                                    case "TreeSpecies":
                                                        count = species[value].length
                                                    break;
                                                }
                                                return (
                                                    <tr key={name}>
                                                        <td>{name}</td>
                                                        <td><strong>{value} (1 / {count})</strong></td>
                                                    </tr>
                                                )
                                            }
                                            else {
                                                return (
                                                    <tr key={name}>
                                                        <td>{this.capitalizeFirstLetter(name)}</td>
                                                        <td><strong>{value}</strong></td>
                                                    </tr>
                                                )
                                            }
                                        }
                                    })
                                  }
                              </tbody>
                           </table>
                        </div>
                        <div className="v-spacer">
                        <a target="_blank" href={this.getUrl(this.formatIndex(this.idList[this.state.index]))}><button id="goto" className="goto-button v-spacer" type="button">View at pool.pm</button></a>
                    { this.state.isPurchasable && (
                                     <a target="_blank" href={"https://cnft.io/token.php?id=" + this.state.cnftId}><button style={{backgroundColor: "darkseagreen", color: "white"}} id="buy" type="button">Buy {this.state.price / 1000000} ₳</button></a>
                                    )
                                  }
                     </div>
                     </div>
                  </div>
               </div>
            </div>
        );
    }
}

export default CardanoTrees;
