import React, {Component} from 'react';

import Slider, { Range } from 'rc-slider'
import 'rc-slider/assets/index.css';

const countries = require('../data/country.json')
const rarities = require('../data/rarity.json')
const species = require('../data/species.json')

var stopTreeAnimation = false

class CardanoTreeTimelapse extends Component {

    constructor(props) {
        super(props);

        this.cnftMap = new Map()
        this.idList = Array.from(Array(10000).keys()).map((elem) => this.formatIndex(elem))
        this.setIndex(0)

        this.state = {
            index: 0,
            inputValue: "",
            metadata: "",
            price: "",
            totalFound: 10000,
            html: "",
            date: new Date(),
            sliderValue: 50
        };
    }

    componentDidMount() {
    }

    increaseIndex() {
        if (this.state.index < this.state.resultsCount) {
            this.setIndex(this.state.index + 1)
        }
    }

    decreaseIndex(e) {
        if (this.state.index > 0) {
            this.setIndex(this.state.index - 1)
        }
    }

    setIndex(index) {
        this.exit = true
        fetch("https://pool.pm/asset/e09e4f4217669b7f735b7a3724e835d8d6344db128eb03d6ea72885e.CT" + this.formatIndex(index))
           .then(data => data.json())
           .then(json => {
                const url = json.metadata.files[0].src.join("")
                const split = url.split(",")
                const html = split[1]
                const header = split[0]
                const decodedString = atob(html);
                let sc = decodedString.split("<script>")[1].split("</script>")[0]
                sc += "function dateNow(){var dateText = document.getElementById('timelapse-input');return dateText ? new Date(dateText.value).getTime() : Date.now();}"
                sc = sc.replace("setTimeout", "if (!exit()) {setTimeout")
                sc = sc.replace("fps)", "fps)} else {console.log('stopping');}")
                sc = sc.replaceAll("Date.now()", "dateNow()")
                this.script = Function("exit", sc)
                this.setState({
                    index: index,
                    html: decodedString,
                    metadata: json.metadata,
                    resultsCount: this.idList.length,
                    inputValue: index + 1,
                    date: new Date(),
                    sliderValue: 50
                }, () => {
                    this.exit = false
                    this.script(this.getExit.bind(this))
                })
           })
           .catch((err) => { console.log(err) })
    }

    getExit() {
        return this.exit
    }

    getUrl(index) {
        return "https://pool.pm/e09e4f4217669b7f735b7a3724e835d8d6344db128eb03d6ea72885e.CT" + this.formatIndex(index)
    }

    formatIndex(index) {
        if (index == null) {
            return ""
        }
        const len = index.toString().length
        const leadingZeros = 4 - len
        let formattedIndex = ""
        for (var i = 0; i < leadingZeros; i++) {
            formattedIndex += "0"
        }
        return formattedIndex + index
    }

    updateInput(e) {
        this.setState({
            inputValue: e.target.value
        })
    }

    onFormSubmit(e) {
        e.preventDefault()
        const formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData);
        if (formProps.index) {
            this.setIndex(formProps.index - 1)
        }
        else {
            this.setIndex(formProps.treeId, true)
        }
    }

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    lastSliderValue = 50

    handleSlider(e) {
        const diff = this.lastSliderValue - e
        const date = this.state.date
        var newDate = new Date(date.setMonth(date.getMonth() - diff));
        this.setState({
            sliderValue: e,
            date: newDate
        })
    }

    changeDate(e) {
        this.setState({
            date: e.target.value
        })
    }

    render() {
        return (
            <div className="content">
               <div className="container">
                  <div className="container half-padding-top">
                     <h2 className="main-teaser-title">CardanoTrees #{this.formatIndex(this.idList[this.state.index])}</h2>
                     <br/>
                  </div>
                  <div className="container row">
                     <div className="col-md-6">
                        <input style={{ width:"500px" }}
                            type="text"
                            id="timelapse-input"
                            value={this.state.date}
                            onChange={this.changeDate.bind(this)}/>
                        <div className="treeview-navigation">
                            <Slider value={this.state.sliderValue} onChange={this.handleSlider.bind(this)}/>
                            <canvas style={{padding: "0", margin: "auto", display: "block"}} id="tcanv"/>
                        </div>
                     </div>
                     <div className="col-md-3">
                        <form onSubmit={this.onFormSubmit.bind(this)}>
                            <div style={{width: "300px"}}>
                           <input className="u-full-width search-input"
                              style={{width: "100px"}}
                              type="text"
                              name="treeId"
                              placeholder={"Tree id #"}
                              id="query"
                              autoComplete="off"/>
                           <button id="prev" type="submit">Jump to</button>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
        );
    }
}

export default CardanoTreeTimelapse;
