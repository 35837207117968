import React, { Component } from 'react';

import Widget from "./widgets/Widget";
import { Link } from "react-router-dom";
import DynamicContentHead from "./DynamicContentHead";

class MyComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            validators: [],
            environment: "mainnet",
            depth: 4
        }
        this.validators = {}
        this.proxyURL = "https://proxy.cunum.com?url="
    }

    componentDidMount() {
        this.intervalResults = setInterval(() => {
            if (this.state.validators !== Object.values(this.validators)) {
                this.setState({ validators: Object.values(this.validators) })
            }
        }, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.intervalResults)
    }

    async request(url, method) {
        return fetch(url, {
            method: 'POST',
            headers: {
             'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "jsonrpc": "2.0",
              "id": "dontcare",
              "method": method,
              "params": []
            })
        })
        .then((response) => response.json())
        .catch((error) => console.log(error))
    }

    async fetchNetworkInfo(url, depth) {
        if (depth > this.state.depth) {
            return
        }
        const data = await this.request(url, "network_info")
        if (!data || !data.result) {
            return
        }
        data.result.active_peers.forEach(async (activePeer) => {
            const knownProducer = data.result.known_producers.filter((prod) => prod.peer_id === activePeer.id)
            const account_id = knownProducer.length > 0 ? knownProducer[0].account_id : null
            if (account_id && !this.validators[account_id]) {
                const peerUrl = this.proxyURL + "http://" + activePeer.addr.split(":")[0] + ":3030"
                const status = await this.request(peerUrl, "status")
                this.fetchNetworkInfo(this.proxyURL + "http://" + activePeer.addr.split(":")[0] + ":3030", depth + 1)
                console.log("fetching " + peerUrl)
                const syncing = status && status.result ? status.result.sync_info.syncing : null
                const latest_block_height = status && status.result ? status.result.sync_info.latest_block_height : null
                const latest_block_time = status && status.result ? status.result.sync_info.latest_block_time : null
                this.validators[account_id] = {
                    account_id,
                    peer_id: activePeer.id,
                    address: activePeer.addr,
                    latest_block_height,
                    syncing,
                    latest_block_time
                }
            }
        })
    }

    onChangeEnvironment(e) {
        const environment = e.target.value
        this.setState({ environment })
    }

    async onSubmit() {
        this.validators = {}
        this.fetchNetworkInfo("https://rpc." + this.state.environment + ".near.org", 0)
        this.setState({
            validators: Object.values(this.validators)
        })
    }

    render() {
        return (
            <div className="content">
                <DynamicContentHead title="Network Scan">
                    <div className="dashboard-form">
                        <label htmlFor="environment">Environment</label>
                        <select id="environment" value={this.state.environment} onChange={this.onChangeEnvironment.bind(this)}>
                            <option value="mainnet">mainnet</option>
                            <option value="testnet">testnet</option>
                        </select>
                    </div>
                    <div className="dashboard-form">
                        <label htmlFor="submit">&nbsp;</label>
                        <input id="submit" type="button" value="Scan Network" onClick={this.onSubmit.bind(this)}/>
                    </div>
                </DynamicContentHead>
                <div className="half-padding-top">
                  <table className="table table-sm delegation-table" style={{width:"700px",margin:"30px auto"}}>
                    <thead>
                      <tr>
                          <th>Account ID</th>
                          <th>IP</th>
                      </tr>
                    </thead>
                    <tbody>
                        {this.state.validators.map((validator, index) => {
                            return (
                                <tr key={index}>
                                    <td>{validator.account_id}</td>
                                    <td onClick={() => {navigator.clipboard.writeText(validator.address)}}>{validator.address}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                  </table>
                </div>
            </div>
        );
    }
}

export default MyComponent;
