import React, { Component } from 'react';

import Widget from "./widgets/Widget";
import { Link } from "react-router-dom";
import DynamicContentHead from "./DynamicContentHead";

class MyComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            delegators: [],
            accountId: "",
            environment: "mainnet",
            placeholderAccountId: "xx.poolv1.near"
        }
        this.proxyURL = "https://proxy.cunum.com?url="
    }

    yoctoNearToNear(value) {
        return Math.round(value / Math.pow(10, 24) * 100) / 100
    }

    async loadDelegationData(accountId, environment) {
        if (accountId && environment) {
            fetch("https://rpc." + environment + ".near.org", {
                   method: 'POST',
                   headers: {
                     'Content-Type': 'application/json'
                   },
                   body: JSON.stringify({
                        "jsonrpc": "2.0",
                        "id": "dontcare",
                        "method": "query",
                        "params": {
                            "request_type": "call_function",
                            "finality": "final",
                            "account_id": accountId.toLowerCase(),
                            "method_name": "get_accounts",
                            "args_base64": btoa(JSON.stringify({
                                "from_index": 0,
                                "limit": 100000
                            }))
                        }
                    })
                })
                .then((response) => response.json())
                .then((data) => {
                    const delegators = JSON.parse(String.fromCharCode(...data.result.result))
                    let staked_total = 0
                    let unstaked_total = 0
                    delegators.forEach((delegator) => {
                        staked_total += this.yoctoNearToNear(delegator.staked_balance)
                        unstaked_total += this.yoctoNearToNear(delegator.unstaked_balance)
                        delegator.staked_balance = this.yoctoNearToNear(delegator.staked_balance)
                        delegator.unstaked_balance = this.yoctoNearToNear(delegator.unstaked_balance)
                    })
                    this.setState({
                        delegators,
                        staked_total,
                        unstaked_total
                    })
                })
                .catch((error) => console.error(error))
        }
    }

    onChangeEnvironment(e) {
        const environment = e.target.value
        this.setState({
            environment,
            placeholderAccountId: environment === "mainnet" ? "xx.poolv1.near" : "xx.pool.f863973.m0"
        })
    }

    keyPress(e) {
        if (e.keyCode == 13){
            this.onSubmit()
        }
    }

    onSubmit() {
        const accountId = (this.state.environment === "mainnet" && !this.state.accountId.includes(".poolv1.near"))
            ? this.state.accountId + ".poolv1.near"
            : (this.state.environment === "testnet" && !this.state.accountId.includes(".pool.f863973.m0"))
              ? this.state.accountId + ".pool.f863973.m0"
              : this.state.accountId
        this.loadDelegationData(accountId, this.state.environment)
    }

    render() {
        return (
            <div className="content">
                <DynamicContentHead title="Validator Delegation">
                    <div className="dashboard-form">
                        <label htmlFor="accountId">Account ID</label>
                        <input id="accountId" placeholder={this.state.placeholderAccountId} type="text" onKeyDown={this.keyPress.bind(this)} value={this.state.accountId} onChange={(e) => this.setState({ accountId: e.target.value })}/>
                    </div>
                    <div className="dashboard-form">
                        <label htmlFor="environment">Environment</label>
                        <select id="environment" onChange={this.onChangeEnvironment.bind(this)}>
                            <option value="mainnet">mainnet</option>
                            <option value="testnet">testnet</option>
                        </select>
                    </div>
                    <div className="dashboard-form">
                        <label htmlFor="submit">&nbsp;</label>
                        <input id="submit" type="button" value="View Delegations" onClick={this.onSubmit.bind(this)}/>
                    </div>
                </DynamicContentHead>
                <div className="half-padding-top">
                  <table className="table table-sm delegation-table" style={{width:"700px",margin:"30px auto"}}>
                    <thead>
                      <tr>
                          <th>Account ID</th>
                          <th>Staked Balance</th>
                          <th>Unstaked Balance</th>
                          <th>Can Withdraw</th>
                      </tr>
                    </thead>
                    <tbody>
                        {this.state.delegators && (
                           this.state.delegators.map((delegator, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{delegator.account_id}</td>
                                        <td>{delegator.staked_balance}</td>
                                        <td>{delegator.unstaked_balance}</td>
                                        <td>{delegator.can_withdraw == 1 ? "✓" : "❌"}</td>
                                    </tr>
                                )
                           })
                        )}
                        {this.state.delegators.length > 0 && (
                            <tr>
                                <td></td>
                                <td><strong>{Math.round(this.state.staked_total * 100) / 100}</strong></td>
                                <td><strong>{Math.round(this.state.unstaked_total * 100) / 100}</strong></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                  </table>
                </div>
            </div>
        );
    }
}

export default MyComponent;
