import React, { Component } from 'react';

class Home extends Component {

    render() {
        return (
            <div>
                <div className="jumbotron main-teaser">
                    <div className="container">
                        <h1 className="display-3">API</h1>
                        <p>API documentation of all cunum services</p>
                    </div>
                </div>
                <div className="container half-padding-top">
                    <p>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead className="thead">
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Beschreibung</th>
                                    <th>Version</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Geoinformation Service (GIS)</td>
                                        <td>Geocoding and autocompletion of addresses</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Real Estate Service (RES)</td>
                                        <td>Serves real estate ads</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td>Note Service (NOS)</td>
                                        <td>Processes geo based messages</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">4</th>
                                        <td>Message Service (MS)</td>
                                        <td>Processes image messages</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">5</th>
                                        <td>Image Service (IS)</td>
                                        <td>Stores and serves images</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">6</th>
                                        <td>Neurochat Service (NES)</td>
                                        <td>AI Chatbot</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">7</th>
                                        <td>Hydro Service (HS)</td>
                                        <td>Processes IOT sensor data</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">8</th>
                                        <td>Recipe Service (RS)</td>
                                        <td>Serves recipes</td>
                                        <td>1</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </p>
                </div>
            </div>
        );
    }
}

export default Home;
