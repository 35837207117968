import React, { Component } from 'react';

import Widget from "./Widget";

class WidgetRow extends Component {

    getStatusColor(value) {
        if (value === 1) {
            return "red"
        }
        return "green"
    }

    render() {
        return (
            <div className="dashboard-widget-row">
                <div className="dashboard-widget-row-header" onClick={() => {navigator.clipboard.writeText("B62qpYngyqVnBCs92WoPCYQxLs4arKnBSppBr1rHtshmtWY19hVdJd3")}}>
                    {this.props.row.accountId ? this.props.row.accountId.split(".")[0] : "Account ID Unset"} ({this.props.row.environment + " " + this.props.row.version})
                    &nbsp;<span style={{color: this.getStatusColor(this.props.row.syncing)}}>{this.props.row.syncing === "0" ? "SYNCED" : "SYNCING"}</span>
                </div>
                <div className="dashboard-widget-row-header-epoch-progress">
                    Epoch progress: {this.props.row.epochProgress || "?"}
                </div>
                {this.props.closable && (
                    <img className="dashboard-widget-row-close" onClick={() => this.props.removeRow(this.props.row.node)} src="/img/close.svg"/>
                )}

                {
                    this.props.row.widgets.map((widget, widgetIndex) => {
                        if (widget) {
                            return <Widget
                               key={widgetIndex}
                               value={widget.value}
                               title={widget.title}
                               subtitle={widget.subtitle}
                               subtitle2={widget.subtitle2}
                               updated={widget.updated}
                               meter={widget.meter}
                               background={widget.background}
                               displayValue={widget.displayValue}
                               greenThreshold={widget.greenThreshold}
                               yellowThreshold={widget.yellowThreshold}
                            />
                        }
                        else {
                            return <Widget
                                key={widgetIndex}
                                value={""}
                                title={""}
                                subtitle={""}
                                subtitle2={""}
                                updated={""}
                                meter={""}
                                background={""}
                                displayValue={""}
                                greenThreshold={0}
                                yellowThreshold={0}
                            />
                        }
                    })
                }

            </div>
        );
    }
}

export default WidgetRow;
