import React, {Component} from 'react';

class AdaPoolWidget extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pledge: "",
            stake: "",
            cost: "",
            margin: "",
            blocksMinted: "",
            delegators: "",
            roaLifetime: "",
        };
        this.loadPoolData();
    }

    loadPoolData = () => {
        fetch("https://js.adapools.org/pools/e4e8771692a6c73270d01852a5f2bb10bbf35388d43c792e883cc824/summary.json")
                .then(response => response.json())
                .then(json => {
                    const cost = (json.data.tax_fix / 1000000).toFixed(2) + " ₳"
                    const margin = (json.data.tax_ratio * 100).toFixed(2) + " %"
                    const pledge = (json.data.pledge / 1000000).toFixed(2) + " ₳"
                    const blocksMinted = json.data.blocks_lifetime
                    const stake = (json.data.total_stake / 1000000).toFixed(2) + " ₳"
                    const roaLifetime = (json.data.roa_lifetime * 100).toFixed(2) + " %"
                    const delegators = json.data.delegators

                    this.setState({
                        cost,
                        margin,
                        pledge,
                        blocksMinted,
                        stake,
                        roaLifetime,
                        delegators
                    })
                })
                .catch(error => console.error(error));
    }

    lovelaceToAda(input, decimals = 2) {
        const splitUnitValue = input.split(" ");
        if (splitUnitValue && splitUnitValue.length > 1) {
            return parseFloat(splitUnitValue[0] / 1000000).toFixed(decimals) + " " + splitUnitValue[1];
        }
        return "";
    }

    render() {
        return (
            <div>
                <h4>Pool Stats</h4>
                <table className="pool-stats-table">
                    <tbody>
                        <tr>
                            <td>Pool Ticker</td>
                            <td>CUNUM</td>
                        </tr>
                        <tr>
                            <td>Pool ID</td>
                            <td>
                                <a style={{marginLeft: "0px"}}
                                   target="_blank"
                                   rel="nofollow noopener noreferrer"
                                   href="https://cardanoscan.io/pool/e4e8771692a6c73270d01852a5f2bb10bbf35388d43c792e883cc824">
                                    e4e8771692a6c73270d01852a5f2bb10bbf35388d43c792e883cc824
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Pledge</td>
                            <td>{this.state.pledge}</td>
                        </tr>
                        <tr>
                            <td>Stake</td>
                            <td>{this.state.stake}</td>
                        </tr>
                        <tr>
                            <td>Cost</td>
                            <td>{this.state.cost}</td>
                        </tr>
                        <tr>
                            <td>Margin</td>
                            <td>{this.state.margin}</td>
                        </tr>
                        <tr>
                            <td>Delegators</td>
                            <td>{this.state.delegators}</td>
                        </tr>
                        <tr>
                            <td>Lifetime Blocks</td>
                            <td>{this.state.blocksMinted}</td>
                        </tr>
                        <tr>
                            <td>ROA Lifetime</td>
                            <td>{this.state.roaLifetime}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default AdaPoolWidget;
