import React, { Component } from 'react';
import { isIPAddress } from 'ip-address-validator';

import WidgetRow from "./widgets/WidgetRow";
import DynamicContentHead from "./DynamicContentHead";
import { toast } from 'react-toastify';

class MyComponent extends Component {

    constructor(props) {
        super(props)
        this.nodes = JSON.parse(localStorage.getItem('nodes')) || []
        if (this.nodes.length === 0) {
            this.nodes = ["cunum.com:3030"]
        }
        this.data = []
        this.state = {
            data: [],
            metrics: []
        }
        this.environments = ["testnet", "mainnet"]
        this.proxyURL = "https://proxy.cunum.com?url="
        this.validatorStatus = {}
        this.validatorStake = {}
        this.validatorShards = {}
        this.epochStartHeight = {}
        this.delegators = {}
        this.seatPrice = {}
        this.blockUpdateState = false
        this.lastTime = {}
        this.lastBlocksProcessedTotal = {}
        this.lastTransactionProcessedTotal = {}
    }

    componentDidMount() {
        if (this.nodes.length > 0) {
            toast.info("Loading previously configured nodes, please wait...")
        }
        this.interval = setInterval(this.fetchData.bind(this), 15000)
        this.fetchData();
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    changeInterval(value) {
        clearInterval(this.interval)
        this.interval = setInterval(this.fetchData.bind(this), value)
    }

    addValidatorStatus(key, value, addInactiveIfNotPresent) {
        let status = this.validatorStatus[key] || []
        if (addInactiveIfNotPresent && status.length == 0) {
            status.push("INACTIVE")
        }
        status.push(value)
        this.validatorStatus[key] = status
    }

    getUpdated() {
        const now = new Date()
        let hours = now.getHours() + ""
        let min = now.getMinutes() + ""
        if (min.length < 2) {
            min = '0' + min
        }
        if (hours.length < 2) {
            hours = '0' + hours
        }
        return "Last updated at " + hours + ":" + min
    }

    fetchSystem() {
        fetch(this.proxyURL + "http://94.16.105.124:7777/status")
                .then(response => response.json())
                .then(data => this.setState(data))
                .catch(error => console.error(error))
    }

    getData(data, path) {
        const extractedData = data.filter(data => data[0] === path)
        if (extractedData.length > 0) {
            return extractedData[0][1]
        }
        return null
    }

    async fetchData() {
        const sys = await fetch(this.proxyURL + "http://cunum.com:7777/v1/status").then(response => response.json()).catch(error => console.error(error))
        const response = await fetch(this.proxyURL + "http://cunum.com:26660/metrics").then(response => response.text()).catch(error => console.error(error))
        if (!sys || !response) {
            return
        }
        const data = response.split("\n").filter(data => !data.startsWith("#")).map(data => data.split(" "))
        const metrics = {
            peers: this.getData(data, 'cometbft_p2p_peers{chain_id="govgen-1"}'),
            stake: this.getData(data, 'cometbft_consensus_validator_power{chain_id="govgen-1",validator_address="891AA052E957D31C648B095910E5561F1839EEE3"}'),
            syncing: this.getData(data, 'cometbft_consensus_state_syncing{chain_id="govgen-1"}'),
            vpTotal: this.getData(data, 'cometbft_consensus_validators_power{chain_id="govgen-1"}'),
            maxBlockLength: this.getData(data, 'cometbft_consensus_validator_last_signed_height{chain_id="govgen-1",validator_address="891AA052E957D31C648B095910E5561F1839EEE3"}'),
            blockHeight: Number(this.getData(data, 'cometbft_consensus_latest_block_height{chain_id="govgen-1"}')),
            missedBlocks: this.getData(data, 'cometbft_consensus_validator_missed_blocks{chain_id="govgen-1",validator_address="891AA052E957D31C648B095910E5561F1839EEE3"}') || 0
        }
        const updated = this.getUpdated()
        const cpu = sys.cpu
        const ram = sys.mem
        const widgets = [
            {
                title: "Peers",
                updated: updated,
                displayValue: metrics.peers,
                value: -1 * metrics.peers,
                greenThreshold: function(val) { return val < 10 },
                yellowThreshold: function(val) { return val < 30 }
            },
            {
                title: "Block height",
                subtitle: (metrics.maxBlockLength - metrics.blockHeight) + " left",
                value: (metrics.maxBlockLength - metrics.blockHeight),
                updated: updated,
                displayValue: metrics.blockHeight,
                greenThreshold: function(val) { return val < 10 },
                yellowThreshold: function(val) { return val < 30 }
            },
            {
                title: "Voting Power",
                subtitle: Math.round(metrics.stake * 100 / metrics.vpTotal) + " %",
                updated: updated,
                displayValue: metrics.stake,
                value: metrics.stake,
                greenThreshold: function(val) { return val > 0 }
            },
            {
                title: "CPU",
                updated: updated,
                value: cpu / 100,
                meter: true,
                displayValue: cpu + "%",
                greenThreshold: function(val) { return val < .5 },
                yellowThreshold: function(val) { return val < .75 }
            },
            {
                title: "RAM",
                updated: updated,
                value: ram / 100,
                meter: true,
                displayValue: ram + " %",
                greenThreshold: function(val) { return val < .4 },
                yellowThreshold: function(val) { return val < .7 }
            },
            {
                title: "Block Miss",
                displayValue: metrics.missedBlocks,
                updated: updated,
                value: metrics.missedBlocks,
                greenThreshold: function(val) { return val === 0 },
                yellowThreshold: function(val) { return val < 1 }
            }
        ]
        this.setState({ data: [{ node: "cunum", environment: "mainnet", widgets, accountId: "cunum", version: "1.1", syncing: metrics.syncing }] })
    }

    async initializeNodes() {

        const initializeNodes = this.data.map((d) => d.node)
        const addedNodes = this.nodes.filter((node) => !initializeNodes.includes(node))
        const removedNodes = initializeNodes.filter((node) => !this.nodes.includes(node))

        this.data = this.data.filter((d) => !removedNodes.includes(d.node))
        if (addedNodes.length == 0) {
           this.setState({ data: this.data })
        }

        let promises = []
        addedNodes.forEach((node) => {
            const statusFetch = fetch(this.proxyURL + "http://" + node + "/status")
                .then((response) => response.json())
                .then((response) => {
                    if (response) {
                        this.data.push({ node, environment: response.chain_id, widgets: new Array(7).fill(null), accountId: response.validator_account_id, version: response.version.version })
                        this.setState({ data: this.data })
                    }
                    else {
                        toast.error("Couldn't connect to node " + node + ". Skipping..")
                    }
                })
                .catch((e) => { console.error(e) })
            promises.push(statusFetch)
        })

        Promise.allSettled(promises).then((result) => {
           this.updateConfigInfo()
           this.updateDelegators()
           this.updateValidatorStatus()
        })
    }

    addNode(host) {
        if (!host.includes(":")) {
            host += ":3030"
        }
        toast.info('Adding a new node ' + host + '. This might take a few seconds, please wait..')
        fetch(this.proxyURL + "http://" + host + "/status")
            .then((response) => response.json())
            .then((data) => {
                this.nodes.push(host)
                localStorage.setItem('nodes', JSON.stringify(this.nodes));
                this.initializeNodes()
            })
            .catch((e) => {
                const hostSplit = host.split(":")
                toast.error('No valid host (' + hostSplit[0] + ') or RPC port (' + hostSplit[1] + ') of node not reachable.')
                console.error(e);
            })
    }

    formatStake(stake) {
        return Math.round(stake / 1000000000000000000000000)
    }

    keyPress(e) {
        if (e.keyCode == 13){
            this.addNode(this.state.host)
        }
    }

    removeRow(remove) {
        this.nodes = this.nodes.filter((node) => node !== remove)
        localStorage.setItem('nodes', JSON.stringify(this.nodes));
        this.initializeNodes()
    }

    render() {
        return (
            <div className="content">
                <DynamicContentHead title={"Validator Monitoring"}>
                    <div className="dashboard-form">
                        <label htmlFor="update_interval">Update Interval</label>
                        <select id="update_interval" onChange={(e) => this.changeInterval(e.target.value)}>
                            <option value="15000">15s</option>
                            <option value="30000">30s</option>
                            <option value="60000">1min</option>
                            <option value="300000">5min</option>
                        </select>
                    </div>
                    <div className="dashboard-form">
                        <label htmlFor="url">RPC Host</label>
                        <input id="url" placeholder="127.0.0.1" type="text" onKeyDown={this.keyPress.bind(this)} onChange={(e) => this.setState({ host: e.target.value })}/>
                    </div>
                    <div className="dashboard-form">
                        <label htmlFor="submit">&nbsp;</label>
                        <input id="submit" type="button" value="Add Node" onClick={() => this.addNode(this.state.host)}/>
                    </div>
                </DynamicContentHead>
                <div className="dashboard-content half-padding-top">
                    { this.nodes.length > 0 && this.state.data.length === 0 && (
                        <div className="spinner">
                          <div className="lds-ring-black"><div></div><div></div><div></div><div></div></div>
                          <div className="loading-text">Loading...</div>
                        </div>
                    )}
                    { this.state.data && (
                      this.state.data.map((row, rowIndex) => {
                        return <WidgetRow
                            row={row}
                            closable={this.state.data.length > 1}
                            key={rowIndex}
                            removeRow={this.removeRow.bind(this)}
                        />
                      })
                    )}
                </div>
            </div>
        );
    }
}

export default MyComponent;
