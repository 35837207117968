import React, { Component } from 'react';

class Contact extends Component {

    render() {
        return (
            <div>
                <div className="jumbotron main-teaser">
                    <div className="container no-padding-top">
                        <h1 className="display-3">Impressum</h1>
                        <p>Sollten Sie Fragen, Anmerkungen oder sonstige Anliegen haben, kontaktieren Sie uns einfach,
                            indem Sie unten genannte Kontaktdaten nutzen</p>
                    </div>
                </div>

                <div className="container half-padding-top">
                    <h5>Kontakt</h5>
                    <p>Christian Mahler<br/>
                       E-Mail: <a href="mailto:christian.mahler@cunum.com">christian.mahler@cunum.com</a><br/>
                    </p>
                </div>

                <div className="container no-padding-top">

                    <hr/>

                    <h5>Disclaimer – rechtliche Hinweise</h5>
                    § 1 Haftungsbeschränkung<br/>
                    Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt. Der
                    Anbieter übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit
                    und Aktualität der bereitgestellten Inhalte. Die Nutzung der Inhalte der
                    Website erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete
                    Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung
                    des Anbieters wieder. Mit der reinen Nutzung der Website des Anbieters kommt
                    keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.
                    <br/><br/>
                    § 2 Externe Links<br/>
                    Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links").
                    Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der
                    Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden
                    Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem
                    Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei
                    Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der
                    verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich
                    der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen
                    macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne
                    konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von
                    Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.
                    <br/><br/>
                    § 3 Urheber- und Leistungsschutzrechte<br/>
                    Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen
                    Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und
                    Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen
                    schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies
                    gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung,
                    Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken
                    oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter
                    sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder
                    Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
                    strafbar. Lediglich die Herstellung von Kopien und Downloads für den
                    persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.
                    <br/><br/>
                    Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher
                    Erlaubnis zulässig.
                    <br/><br/>
                    § 4 Besondere Nutzungsbedingungen<br/>
                    Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den
                    vorgenannten Paragraphen abweichen, wird an entsprechender Stelle
                    ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen
                    Einzelfall die besonderen Nutzungsbedingungen.<br/> <br/>
                    <p className="external-link">Quelle: <a className="external-link" rel="nofollow" href="http://www.juraforum.de/impressum-generator/">Impressumsgenerator</a> entwickelt mit <a className="external-link" rel="nofollow" href="http://www.rechtsanwaelte-hannover.eu/rechtsgebiete/verkehrsrecht/">Anwalt Verkehrsrecht in Hannover</a></p>
                </div>
            </div>
        );
    }
}

export default Contact;
