import React, { Component } from 'react';

class Home extends Component {

    render() {
        return (
            <div className="content near-background">
                <div className="container half-padding-top">
                    <div className="center-main">
                        <div className="node-box">Cunum Node Germany | ACTIVE</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
