import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { Switch } from "react-router";

import Header from './Header';
import Home from "./Home";
import OpenSource from "./OpenSource";
import MobileApps from "./MobileApps";
import Privacy from "./Privacy";
import Contact from "./Contact";
import NotFound from "./NotFound";
import StickyNotes from "./StickyNotes";
import Footer from "./Footer";
import Games from "./Games";
import Crypto from "./Crypto";
import CardanoTrees from "./CardanoTrees";
import CardanoTreeGalery from "./CardanoTreeGalery";
import CardanoTreeTimelapse from "./CardanoTreeTimelapse";

class App extends Component {

    render() {
        return (
            <div className="App">
                <Header/>
                <div className="content">
                    <Switch>
                        <Route exact path='/' component={Home}/>
                        <Route exact path='/opensource/' component={OpenSource}/>
                        <Route exact path='/apps/' component={MobileApps}/>
                        <Route exact path='/games/' component={Games}/>
                        <Route exact path='/crypto/' component={Crypto}/>
                        <Route exact path='/privacy/' component={Privacy}/>
                        <Route exact path='/contact/' component={Contact}/>
                        <Route exact path='/stickynotes/' component={StickyNotes}/>
                        <Route exact path='/cardanotrees/' component={CardanoTrees}/>
                        <Route exact path='/cardanotrees/galery' component={CardanoTreeGalery}/>
                        <Route exact path='/cardanotrees/galery/:walletAddress' component={CardanoTreeGalery}/>
                        <Route exact path='/cardanotrees/galery/:walletAddress/:assetId' component={CardanoTreeGalery}/>
                        <Route exact path='/cardanotrees/timelapse' component={CardanoTreeTimelapse}/>
                        <Route component={NotFound}/>
                    </Switch>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default App;