import React, { Component } from 'react';

import Widget from "./widgets/Widget";
import { Link } from "react-router-dom";
import Chart from 'chart.js/auto';
import DynamicContentHead from "./DynamicContentHead";

class MyComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            stats: {},
            environment: "mainnet",
            validators: [],
            environments: [],
            activeValidator: "",
            epochs: 100,
            validatorStats: null,
            syncing: false
        }
    }

    componentDidMount() {
        const ctx = document.getElementById('myChart');
        this.chart = new Chart(ctx, {
            type: 'line',
            data: {
                datasets: []
            },
            options: {
                plugins: {
                    tooltip: {
                      callbacks: {
                          title: (context) => {
                            return "Epoch " + context[0].label;
                          },
                          label: (context) => {
                            let prefix = this.nameFromAccountId(context.dataset.label) + ": "
                            let value = context.formattedValue
                            if (this.chart.options.scales.y.title.text.includes("Uptime")) {
                                value = Math.round(context.parsed.y * 100) / 100 + "%"
                            }
                            else if (this.chart.options.scales.y.title.text.includes("Stake")) {
                                value = context.formattedValue + " NEAR"
                            }
                            return prefix + value
                          }
                      }
                    }
              },
              parsing: {
                  xAxisKey: 'epoch_height',
                  yAxisKey: 'uptime'
              },
              scales: {
                x: {
                    title: {
                    color: 'black',
                    display: true,
                    text: 'Epoch'
                    },
                    type: "linear",
                },
                y: {
                    title: {
                      color: 'black',
                      display: true,
                      text: 'Uptime (%)'
                    },
                    type: 'linear',
                }
              }
            }
        })
        this.update()
    }

    getRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    calculateRollingAverage(validatorData) {
        let values = {}
        for (const [accountId, docs] of Object.entries(validatorData)) {
            const docsSortedByEpoch = docs.sort((doc1, doc2) => doc1.epoch_height - doc2.epoch_height)
            let counter = 0
            let rollingSums = {
                stake: { sum: 0, count: 0 },
                delegation_count: { sum: 0, count: 0 },
                uptime: { sum: 0, count: 0 },
                uptime_blocks: { sum: 0, count: 0 },
                uptime_chunks: { sum: 0, count: 0 },
            }
            values[accountId] = []
            for (let doc of docsSortedByEpoch) {
                if (doc.stake) {
                    rollingSums.stake.sum += doc.stake
                    rollingSums.stake.count += 1
                }
                if (doc.delegation_count) {
                    rollingSums.delegation_count.sum += doc.delegation_count
                    rollingSums.delegation_count.count += 1
                }

                rollingSums.uptime.sum += doc.uptime || 0
                rollingSums.uptime.count += 1
                rollingSums.uptime_blocks.sum += doc.uptime_blocks || 0
                rollingSums.uptime_blocks.count += 1
                rollingSums.uptime_chunks.sum += doc.uptime_chunks || 0
                rollingSums.uptime_chunks.count += 1

                values[accountId].push({
                    stake: rollingSums.stake.sum / rollingSums.stake.count,
                    delegation_count: rollingSums.delegation_count.sum / rollingSums.delegation_count.count,
                    uptime: rollingSums.uptime.sum / rollingSums.uptime.count,
                    uptime_blocks: rollingSums.uptime_blocks.sum / rollingSums.uptime_blocks.count,
                    uptime_chunks: rollingSums.uptime_chunks.sum / rollingSums.uptime_chunks.count,
                    epoch_height: doc.epoch_height
                })
            }
        }
        return values
    }

    calculateAverageMisses(validatorData) {
        let values = {}
        for (let docs of Object.values(validatorData)) {
            for (let doc of docs) {
                let epochAverage = values[doc.epoch_height] || {
                    missedCounter: 0,
                    producedCounter: 0,
                    epoch_height: doc.epoch_height,
                }

                if (doc.uptime) {
                    epochAverage.producedCounter += 1
                } else {
                    epochAverage.missedCounter += 1
                }

                values[doc.epoch_height] = epochAverage
            }
        }

        let result = []
        for (const value of Object.values(values)) {
            result.push({
                 uptime: value.producedCounter * 100 / (value.producedCounter + value.missedCounter),
                 epoch_height: value.epoch_height
            })
        }

        return result
    }

    calculateAverageAllValidators(validatorData) {
        let values = {}
        for (let docs of Object.values(validatorData)) {
            for (let doc of docs) {
                const epochAverage = values[doc.epoch_height] || {
                   stake: 0,
                   delegation_count: 0,
                   uptime: 0,
                   uptime_blocks: 0,
                   uptime_chunks: 0,
                   epoch_height: doc.epoch_height,
                   counter: 0,
                   delegationCounter: 0,
                   stakeCounter: 0
                }

                if (doc.delegation_count) {
                    epochAverage.delegation_count += doc.delegation_count
                    epochAverage.delegationCounter += 1
                }
                if (doc.stake) {
                    epochAverage.stake += doc.stake
                    epochAverage.stakeCounter += 1
                }
                if (doc.uptime) {
                    epochAverage.counter += 1
                    epochAverage.uptime += doc.uptime
                    epochAverage.uptime_blocks += doc.uptime_blocks
                    epochAverage.uptime_chunks += doc.uptime_chunks
                }

                values[doc.epoch_height] = epochAverage
            }
        }

        let result = []
        for (const value of Object.values(values)) {
            result.push({
                 stake: value.stake / value.stakeCounter,
                 delegation_count: value.delegation_count / value.delegationCounter,
                 uptime: value.uptime / value.counter,
                 uptime_blocks: value.uptime_blocks / value.counter,
                 uptime_chunks: value.uptime_chunks / value.counter,
                 epoch_height: value.epoch_height
            })
        }

        return result
    }

    update(environments = ["mainnet", "testnet"]) {
        this.validators = {};
        this.averageValues = {};
        this.missedValues = {};
        this.rollingAverageValues = {};
        this.latestEpoch = {};
        environments.forEach(async (environment) => {
            fetch("https://near.cunum.com/validators/" + environment)
                .then((response) => response.json())
                .then((data) => {
                    if (data.response.numFound === 0) {
                        return
                    }
                    this.validators[environment] = {}
                    this.latestEpoch[environment] = data.response.docs.reduce((p, v) => p.epoch_height > v.epoch_height ? p.epoch_height : v.epoch_height )

                    data.response.docs.forEach((doc) => {
                        let validator = this.validators[environment][doc.account_id]
                        if (!validator) {
                            validator = []
                        }
                        else {
                            let expectedNextEpoch = validator[validator.length - 1].epoch_height + 1
                            if (expectedNextEpoch < doc.epoch_height) {
                                for (let i = expectedNextEpoch; i < doc.epoch_height; i++) {
                                    validator.push({ epoch_height: i })
                                }
                            }
                        }
                        doc.stake /= Math.pow(10, 24)
                        const uptimeChunks = doc.chunks_expected === 0 ? 1 : (doc.chunks_produced / doc.chunks_expected)
                        const uptimeBlocks = doc.blocks_expected === 0 ? 1 : (doc.blocks_produced / doc.blocks_expected)
                        doc.uptime = uptimeChunks * uptimeBlocks * 100
                        doc.uptime_chunks = uptimeChunks * 100
                        doc.uptime_blocks = uptimeBlocks * 100
                        validator.push(doc)
                        this.validators[environment][doc.account_id] = validator
                    })

                    for (const [key, value] of Object.entries(this.validators[environment])) {
                        if (value[value.length - 1].epoch_height < this.latestEpoch[environment]) {
                            for (let i = value[value.length - 1].epoch_height + 1; i <= this.latestEpoch[environment]; i++) {
                                value.push({ epoch_height: i })
                            }
                        }
                    }

                    this.missedValues[environment] = this.calculateAverageMisses(this.validators[environment])
                    this.averageValues[environment] = this.calculateAverageAllValidators(this.validators[environment])
                    this.rollingAverageValues[environment] = this.calculateRollingAverage(this.validators[environment])

                    const environments = this.state.environments
                    environments.push(environment)
                    this.setState({environments}, () => {
                        if (environment === this.state.environment) {
                            this.onChangeEnvironment(this.state.environment)
                        }
                    })
                })
                .catch((e) => {
                    console.error(e)
                })
        })
    }

    onChangeEnvironment(environment) {

        const accountIds = Object.entries(this.validators[environment])
            .filter(([key, value]) => value.some((val) => val.environment === environment))
            .map(([key, value]) => key)
            .sort()
        const activeValidator = environment === "mainnet" ? "cunum.poolv1.near" : accountIds[0]
        this.setState({ validators: accountIds, environment }, () => {
            this.onChangeValidator(activeValidator)
        })
    }

    onChangeEpochs(epochs) {
        this.setState({ epochs }, () => this.onChangeValidator(this.state.activeValidator))
    }

    onChangeMetric(metric) {
        this.chart.options.parsing.yAxisKey = metric
        if (metric.includes("uptime")) {
            this.chart.options.scales.y = {
                title: {
                    color: 'black',
                    display: true,
                    text: 'Uptime (%)'
                }
            }
        }
        else {
            const yTitle = metric == "stake" ? "Stake (NEAR)" : "Delegators"
            this.chart.options.scales.y = {
                title: {
                    color: 'black',
                    display: true,
                    text: yTitle
                },
                type: 'logarithmic'
            }
        }
        this.chart.update()
    }

    onChangeValidator(activeValidator) {
        let data = this.validators[this.state.environment][activeValidator]
        const average = this.averageValues[this.state.environment]
        const missed = this.missedValues[this.state.environment]
        const rollingAverage = this.rollingAverageValues[this.state.environment][activeValidator]
        if (data) {

            data = data.sort((a, b) => a.epoch_height - b.epoch_height)
            const latestEpoch = this.latestEpoch[this.state.environment]
            let dataMin = this.state.epochs === "all" ? 0 : latestEpoch - this.state.epochs
            dataMin = Math.max(data[0].epoch_height - 1, dataMin)
            const averageMin = this.state.epochs === "all" ? 0 : latestEpoch - this.state.epochs
            const max = latestEpoch

            const decimatedData = data.filter((d) => d.epoch_height > dataMin && d.epoch_height <= max)
            const decimatedAverage = average.filter((avg) => avg.epoch_height > averageMin && avg.epoch_height <= max)
            const decimatedMissed = missed.filter((missed) => missed.epoch_height > averageMin && missed.epoch_height <= max)
            const decimatedRollingAverage = rollingAverage.filter((avg) => avg.epoch_height > dataMin && avg.epoch_height <= max)
            const poolName = this.nameFromAccountId(activeValidator)

            const epochsMissed = data.filter((d) => d.epoch_height > dataMin && d.epoch_height <= max && d.uptime == null).length
            const epochsValidated = decimatedData.length - epochsMissed
            let sum = 0
            decimatedData.forEach((data) => sum += data.uptime || 0)
            const averageUptime = sum / decimatedData.length

            const validatorStats = {
                poolName: this.nameFromAccountId(activeValidator),
                averageUptime: (Math.round(averageUptime * 100) / 100) + "%",
                epochsValidated,
                epochsMissed,
            }

            this.setState({ activeValidator, validatorStats })

            this.chart.data.datasets = [
                { label: poolName, backgroundColor: "blue", data: decimatedData, borderColor: "blue" },
//                { label: "Rolling Average (" + poolName + ")", backgroundColor: "lightblue", data: decimatedRollingAverage },
                { label: "Average (all validators)", backgroundColor: "lightgray", data: decimatedAverage },
//                { type: 'bar', label: "Validated (all validators)", backgroundColor: "lightblue", data: decimatedMissed },
            ]
            this.chart.update()
        }
    }

    nameFromAccountId(accountId) {
        return accountId.split(".")[0]
    }

    updateData() {
        this.setState({ syncing: true })
        fetch("https://near.cunum.com/validators/sync/" + this.state.environment).catch((error) => console.error(error))
        setTimeout(() => {
            this.update([this.state.environment])
            this.setState({ syncing: false })
        }, 60 * 1000)
    }

    render() {

        return (
            <div className="content">
                <DynamicContentHead title={"Validator History"}>
                    {!this.state.syncing && (
                        <div className="dashboard-form">
                            <label htmlFor="sync">&nbsp;</label>
                            <img className="sync-button" width="30" height="30" id="sync" onClick={this.updateData.bind(this)} src="/img/sync.svg" />
                        </div>
                    )}
                    <div className="dashboard-form">
                        <label htmlFor="epochs">Epochs</label>
                        <select id="epochs" defaultValue={this.state.epochs} onChange={(e) => this.onChangeEpochs(e.target.value)}>
                            <option value="10">Last 10</option>
                            <option value="50">Last 50</option>
                            <option value="100">Last 100</option>
                            <option value="250">Last 250</option>
                            <option value="500">Last 500</option>
                            <option value="all">From Genesis</option>
                        </select>
                    </div>
                    <div className="dashboard-form">
                        <label htmlFor="environment">Metric</label>
                        <select id="metric" onChange={(e) => this.onChangeMetric(e.target.value)}>
                            <option value="uptime">Uptime</option>
                            <option value="stake">Stake</option>
                            <option value="delegation_count">Delegation Count</option>
                            <option value="uptime_blocks">Uptime Blocks</option>
                            <option value="uptime_chunks">Uptime Chunks</option>
                        </select>
                    </div>
                    <div className="dashboard-form">
                        <label htmlFor="validator">Validator</label>
                        <select id="validator" value={this.state.activeValidator} onChange={(e) => this.onChangeValidator(e.target.value)}>
                            {this.state.validators.map((accountId, rowIndex) => {
                                return <option key={rowIndex} value={accountId}>{this.nameFromAccountId(accountId)}</option>
                            })}
                        </select>
                    </div>
                    <div className="dashboard-form">
                        <label htmlFor="environment">Environment</label>
                        <select id="environment" value={this.state.environment} onChange={(e) => this.onChangeEnvironment(e.target.value)}>
                            {this.state.environments.map((environment, rowIndex) => {
                                return <option key={rowIndex} value={environment}>{environment}</option>
                            })}
                        </select>
                    </div>
                </DynamicContentHead>
                <div className="half-padding-top">
                    <div className="container">
                        <span className="dashboard-analytics-statistics-epoch">
                            { this.state.validatorStats && (
                                <ul>
                                    <li>{this.state.validatorStats.poolName}</li>
                                    <li style={{fontSize:"16px"}}>Average Uptime: {this.state.validatorStats.averageUptime}</li>
                                    <li style={{fontSize:"16px"}}>Validated Epochs: {this.state.validatorStats.epochsValidated}</li>
                                    <li style={{fontSize:"16px"}}>Missed Epochs: {this.state.validatorStats.epochsMissed}</li>
                                </ul>
                            )}
                        </span>
                        { this.state.validators.length === 0 && (
                            <div className="spinner">
                              <div className="lds-ring-black"><div></div><div></div><div></div><div></div></div>
                              <div className="loading-text">Loading...</div>
                            </div>
                        )}
                        <canvas style={{marginTop: "30px", display: this.state.validators.length === 0 ? "none" : "block"}} id="myChart" width="1000" height="400"></canvas>
                    </div>
                </div>
            </div>
        );
    }
}

export default MyComponent;
