import React, { Component } from 'react';

class MobileApps extends Component {

    render() {
        return (
            <div>
                <div className="jumbotron main-teaser">
                    <div className="container no-padding-top">
                        <h1 className="display-3">Mobile Apps</h1>
                        <p>Hier finden Sie die Dokumentation aller momentan verfügbaren mobilen Apps</p>
                    </div>
                </div>

                <div className="container half-padding-top">

                    <div className="row">
                        <div className="col-md-6">
                            <h2>Jumble Jobs</h2>
                            <p><img src="../../img/jumble.svg" width="100" className="app-icon" alt="Jumble Jobs"/>
                                Gehen Sie auf Jobsuche mit der Jumble Jobs App!

                                Suchen Sie mit Jumble, der Jobsuchmaschine, Stellenangebote und Jobs auf den größten
                                deutschen Jobbörsen, sowie sowie direkt auf den Unternehmensseiten. <a href="#jumble_jobs" role="button">Mehr Infos &raquo;</a>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <h2>Sticky Notes AR</h2>
                            <p><img src="../../img/stickynotes.svg" width="100" className="app-icon" alt="Sticky Notes AR"/>
                                Sticky Notes AR ist eine einzigartige Augmented Reality Nachrichten-App, die in der Lage
                                ist Nachrichten und Bilder in den Raum zu projizieren. Die Nachrichten werden dabei an
                                den zum Zeitpunkt der Erstellung aktuellen Standort des Nutzers fixiert. <a href="#sticky_notes_ar" role="button">Mehr Infos &raquo;</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Reswipes</h2>
                            <p><img src="../../img/reswipes.svg" width="100" className="app-icon" alt="Reswipes"/>
                                Swipen Sie sich durch tausende anregender Rezepte und lassen Sie sich dabei kulinarisch
                                inspirieren! Erstellen Sie neue Rezepte, teilen Sie diese mit anderen Nutzern und
                                bewerten oder bookmarken Sie Rezepte anderer Nutzer. <a href="#reswipes" role="button">Mehr Infos &raquo;</a></p>
                        </div>
                        <div className="col-md-6">
                            <h2>Jumble Immo</h2>
                            <p><img src="../../img/jumbleimmo.svg" width="100" className="app-icon" alt="Jumble Immo"/>
                                Gehen Sie auf Immobilienjagd mit der Jumble Immo App! Suchen Sie Immobilien zur Miete
                                und Kauf auf allen großen deutschen Immobilienportalen mit der Metasuche von Jumble. <a href="#jumbleimmo" role="button">Mehr Infos &raquo;</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Grocery List AR</h2>
                            <p><img src="../../img/grocerylist.svg" width="100" className="app-icon" alt="Grovery List"/>
                                Scanne mit der Kamera Texte nach Lebensmitteln. Die Augmented Reality Ansicht ermöglicht
                                es die im Text enthaltenen Lebensmittel zu Deiner Einkaufsliste hinzuzufügen. <a href="#grocerylist" role="button">Mehr Infos &raquo;</a></p>
                        </div>
                        <div className="col-md-6">
                            <h2>Nine Watch</h2>
                            <p><img src="../../img/ninewatch.svg" width="100" className="app-icon" alt="Ninewatch"/>
                                Mit Nine Watch kommt 9GAG nun auch auf Deine Apple Watch! Schaue Dir lustige Bilder,
                                Memes, Gifs und Videos direkt auf Deiner Apple Watch an, wann und wo Du dich auch
                                befindest. <a href="#ninewatch" role="button">Mehr Infos &raquo;</a></p>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Pickup Notify for Apple Store</h2>
                            <p><img src="../../img/pickupnotify.jpg" width="100" className="app-icon" alt="Pickup Notify"/>
                                Get notified on your watch, if the Apple product of you choice is available for in store
                                pickup! <a href="#pickupnotify" role="button">Mehr Infos &raquo;</a></p>
                        </div>
                    </div>

                    <div className="app-spacer mobile-app-info">
                        <h2 id="jumble_jobs">Jumble Jobs</h2>
                    </div>
                    <p>Gehen Sie auf Jobsuche mit der Jumble Jobs App!

                        Suchen Sie mit Jumble, der Jobsuchmaschine, Stellenangebote und Jobs auf den größten deutschen
                        Jobbörsen, sowie direkt auf den Unternehmensseiten. Stöbern Sie in unseren Berufskategorien und
                        entdecken Sie täglich tausende neue Jobs in ganz Deutschland. Begnügen Sie sich nicht mit einer
                        einzelnen Jobbörse, mit Jumble haben Sie alle verfügbaren Jobs in Deutschland stets im Blick.
                        Jumble klassifiziert und verschlagwortet jede Stellenanzeige, um Ihnen Suche und Einschätzung
                        geeigneter Jobs erheblich zu erleichtern.
                    </p>
                    <h4>Kernfunktionen</h4>
                    <ul>
                        <li>Jobsuche auf den größten deutschen Jobbörsen sowie direkt auf Unternehmensseiten</li>
                        <li>Suchen Sie einfach und schnell Jobs in Ihrer Nähe</li>
                        <li>Suchen Sie nach Stellentitel, Stichwort, Kategorie, Skill, Unternehmen und Ort</li>
                        <li>Erkennen Sie auf einen Blick die Relevanz des Stellenangebots aufgrund der intelligenten
                            Verschlagwortung jeder Stellenanzeige
                        </li>
                        <li>Schränken Sie Ihre Suche ein, indem Sie nach Kategorien, Skills und Schlagworten
                            filtern
                        </li>
                        <li>Sortieren Sie die Suchergebnisse nach Relevanz, Aktualität oder Entfernung</li>
                        <li>Lokalisieren Sie sich automatisch über die GPS Funktionalität Ihres Gerätes*</li>
                        <li>Wählen Sie stufenlos den Umkreis Ihrer Standortsuche, oder suchen Sie nur direkt in
                            Ihrem Ort
                        </li>
                    </ul>

                    <h4>Zusatzfunktionen</h4>
                    <ul>
                        <li>Bookmarken Sie interessante Jobs, um Sie zu einem späteren Zeitpunkt erneut aufzurufen
                        </li>
                        <li>Speichern Sie Suchkonfigurationen (Suchwort, Filter, Sortierung, Lokalisierung), um
                            unterschiedliche Suchen zu einem späteren Zeitpunkt erneut auszuführen
                        </li>
                        <li>Teilen Sie Stellenanzeigen mit Freunden und Bekannten per SMS, Mail, WhatsApp, Facebook,
                            Twitter etc.
                        </li>
                        <li>Drucken Sie Stellenanzeigen auf Ihrem Drucker im lokalen Netz</li>
                    </ul>

                    <h4>Einstellungen</h4>
                    <ul>
                        <li>Aktivieren Sie auch Stellenangebote von Personalvermittlungsfirmen (standardmäßig
                            deaktiviert)
                        </li>
                        <li>Schalten Sie die automatische Verortung bei App-Start an/aus (standardmäßig aktiviert)
                        </li>
                    </ul>
                    <p>
                        * eine Lokalisierung ist auch manuell möglich, so dass kein Zugriff auf Ihre Standortdaten
                        gewährt werden muss<br/><br/>

                        <br/>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://itunes.apple.com/de/app/jumble-jobs/id1126837760?mt=8">
                            <img width="135" height="40" src="../../img/appstore_badge.svg" alt="iOS Appstore Badge"/>
                        </a>
                    </p>

                    <div className="app-spacer">
                        <h2 id="sticky_notes_ar">Sticky Notes AR</h2>
                    </div>
                    <p>Sticky Notes AR ist eine einzigartige Augmented Reality Nachrichten-App, die in der Lage ist
                        Nachrichten und Bilder in den Raum zu projizieren. Die Nachrichten werden dabei an den zum
                        Zeitpunkt der Erstellung aktuellen Standort des Nutzers fixiert und können fortan in der
                        Augmented Reality Ansicht betrachtet und geteilt werden. Auf diese Weise kann man mit
                        Sticky Notes AR seine Umgebung jederzeit und überall mit selbst erstellten virtuellen Inhalten
                        anreichern. Erstellt man eine öffentliche Nachricht, so können alle Nutzer der App, die sich in
                        der Nähe der Nachricht aufhalten, diese ansehen und darauf antworten. Erstellt man eine private
                        Nachrichten, so ist diese nur für den Autor persönlich sichtbar.
                    </p>
                    <h4>Kernfunktionen</h4>
                    <ul>
                        <li>KEINE ZUSÄTZLICHEN KOSTEN: Sticky Notes AR verwendet die Internet-Verbindung
                            (4G/3G/2G/EDGE oder WLAN, falls verfügbar) deines Telefons / Tablets, um Nachrichten zu
                            posten
                        </li>
                        <li>MULTIMEDIA: Veröffentliche Bilder und Texte</li>
                        <li>ÖFFENTLICHE NACHRICHTEN: Nutze die App, um mit anderen Menschen in Kontakt zu treten.
                            Hefte Notizen an Geschäfte, Events, öffentliche Gebäude und Plätze, Ereignisse etc. und
                            teile somit Deine Gedanken mit Jedermann in Deiner Umgebung. Schaue Dir Nachrichten und
                            Bilder anderer Nutzer an und beteilige Dich an Ihren Konversationen.
                        </li>
                        <li>PRIVATE NACHRICHTEN: Nutze die App im privaten Modus, um für Dich wichtige Events, Orte,
                            Geschäfte, Gebäude, Geschehnisse, Objekte etc. zu markieren und mit Inhalten zu
                            versehen, die nur Du allein sehen kannst. Die Inhalte werden als private Nachricht nur
                            auf Deinem eigenen Gerät gespeichert und Niemandem sonst zugänglich gemacht. Private
                            Inhalte werden auch nicht über das Netzwerk verteilt. Schon jemals Dein geparktes Auto
                            nicht mehr gefunden? Markiere es ab sofort mit Sticky Notes AR!
                        </li>
                    </ul>

                    <h4>Einstellungen</h4>
                    <ul>
                        <li>AR Qualität: Wähle eine optimale oder reduzierte AR Erfahrung. Eine optimale AR Erfahrung ist
                            dank Markerless Object Tracking wesentlich eindrücklicher, verursacht jedoch einen höheren
                            Batterieverbrauch und Datenverkehr (WiFi oder LTE wird daher empfohlen).
                        </li>
                        <li>Filtere Nachrichten: Filtere Nachrichten nach dem Typ der Nachricht. Zeige z.B. alle
                            Nachrichten an (default), nur die öffentlichen, nur die privaten, oder nur die, die Du
                            selbst geschrieben hast.
                        </li>
                        <li>Schränke den Umkreis ein: Zu viele Nachrichten in Deiner Umgebung und keine Chance die
                            Übersicht zu behalten? Kein Problem, schränke einfach den Umkreis der
                            Nachrichten-Anzeige ein.
                        </li>
                        <li>Styling: Wähle zwischen zwei Nachrichten Stylings, entweder Sprechblase und Transparent schwarz.</li>li>
                    </ul>
                    <p>
                        <br/>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://itunes.apple.com/de/app/sticky-notes-ar/id1207344660?mt=8">
                            <img width="135" height="40" src="../../img/appstore_badge.svg" alt="iOS Appstore Badge"/>
                        </a>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://play.google.com/store/apps/details?id=com.cunum.stickynotesar">
                            <img height="60" src="../../img/google_play_badge.png" alt="Google Play Store Badge"/>
                        </a>
                    </p>

                    <div className="app-spacer">
                        <h2 id="reswipes" className="v-spacer">Reswipes</h2>
                    </div>
                    <p>Entdecke neue Rezepte mit der Reswipes App. Swipe Dich durch tausende leckere Rezepte und lass
                        Dich kulinarisch inspirieren. Koche Rezepte anderer Nutzer nach und bewerte oder bookmarke
                        diese. Erstelle eigene Rezepte und teilen sie mit anderen Nutzern.
                    </p>
                    <h4>Features im Überblick</h4>
                    <ul>
                        <li>REZEPTE ENTDECKEN: Swipe Dich durch tausende von Rezepten. Sagt Dir eines zu, schau es
                            Dir im Detail an, indem Du auf die Rezeptkarte drückst
                        </li>
                        <li>FILTERN UND SORTIEREN: Filtere die Rezepte nach Rezept-Kategorie oder sortiere Sie nach
                            Aktualität, Bewertung und Zufall
                        </li>
                        <li>REZEPT BOOKMARKEN: Speichere Deine Lieblingsrezepte per Swipe nach oben</li>
                        <li>REZEPT UPVOTEN: Bewerte Rezepte positiv durch Swipe der Rezeptkarte nach rechts</li>
                        <li>EIGENE REZEPTE: Erstelle eigene Rezepte, um sie mit anderen Nutzern zu teilen</li>
                    </ul>
                    <p>
                        <br/>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://itunes.apple.com/de/app/reswipes/id1265675528?mt=8">
                            <img width="135" height="40" src="../../img/appstore_badge.svg" alt="iOS Appstore Badge"/>
                        </a>
                    </p>

                    <div className="app-spacer">
                        <h2 id="jumbleimmo">Jumble Immo</h2>
                    </div>
                    <p>Begnügen Sie sich bei Ihrer Immobiliensuche nicht mit einem einzigen Immobilienportal. Mit der
                        Metasuchmaschine Jumble haben Sie alle verfügbaren Immobilien in Deutschland stets im Blick.
                        Stöbern Sie in unseren Kategorien und entdecken Sie täglich tausende neue Immobilien in ganz
                        Deutschland. Jumble klassifiziert und verschlagwortet jede Immobilienanzeige, um Ihnen Suche und
                        Einschätzung geeigneter Immobilien erheblich zu erleichtern.
                    </p>
                    <h4>Kernfunktionen</h4>
                    <ul>
                        <li>Immobiliensuche auf den größten deutschen Immobilienportalen (Metasuche)</li>
                        <li>Suchen Sie einfach und schnell Wohnungen und Häuser zur Miete oder zum Kauf</li>
                        <li>Erkennen Sie auf einen Blick die Relevanz der Anzeige aufgrund der intelligenten
                            Verschlagwortung jeder Immobilienanzeige
                        </li>
                        <li>Schränken Sie Ihre Suche ein, indem Sie die Suchergebnisse nach den Eigenschaften der
                            Immobilie, der Fläche, der Anzahl der Zimmer und der Miete bzw. des Kaufpreises filtern
                        </li>
                        <li>Sortieren Sie die Suchergebnisse nach Relevanz, Aktualität oder Entfernung</li>
                        <li>Lokalisieren Sie sich automatisch über die GPS Funktionalität Ihres Geräte*</li>
                        <li>Wählen Sie stufenlos den Umkreis Ihrer Standortsuche, oder suchen Sie nur direkt in
                            Ihrem Ort
                        </li>
                    </ul>

                    <h4>Zusatzfunktionen</h4>
                    <ul>
                        <li>Bookmarken Sie interessante Anzeigen, um Sie zu einem späteren Zeitpunkt erneut
                            aufzurufen
                        </li>
                        <li>Speichern Sie Suchkonfigurationen (Suchwort, Filter, Sortierung, Lokalisierung), um
                            unterschiedliche Suchen zu einem späteren Zeitpunkt erneut auszuführen
                        </li>
                        <li>Teilen Sie Immobilienanzeigen mit Freunden und Bekannten per SMS, Mail, WhatsApp,
                            Facebook, Twitter etc.
                        </li>
                        <li>Drucken Sie Anzeigen auf Ihrem Drucker im lokalen Netz aus</li>
                    </ul>

                    <h4>Einstellungen</h4>
                    <ul>
                        <li>Wählen Sie "Miete" um nur Immobilienanzeigen zur Miete angezeigt zu bekommen oder "Kauf"
                            für käufliche Immobilien
                        </li>
                        <li>Schalten Sie die automatische Verortung bei App-Start an/aus (standardmäßig aktiviert)
                        </li>
                    </ul>

                    <p>* eine Lokalisierung ist auch manuell möglich, so dass kein Zugriff auf Ihre Standortdaten
                        gewährt werden muss<br/><br/>

                        <br/>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://itunes.apple.com/de/app/jumble_immo/id1268839298?mt=8">
                            <img width="135" height="40" src="../../img/appstore_badge.svg" alt="iOS Appstore Badge"/>
                        </a>
                    </p>

                    <div className="app-spacer">
                        <h2 id="grocerylist">Grocery List AR</h2>
                    </div>

                    <p>Grocery List AR ermöglicht es Dir Einkaufslisten für Lebensmittel aus beliebigen gedruckten
                        Volltexten wie Rezepten, Zutatenlisten, Speisekarten, Produktetiketten usw. zu erstellen.
                        Hierfür wird der Text von einem Bildschirm oder Papier mit der Kamera abgescannt, die optische
                        Zeichenerkennung identifiziert die Lebensmittel und stellt sie auf Höhe des erkannten
                        Textabschnitts in einer Augmented Reality Ansicht dar. Durch Berührung eines Lebensmittels in
                        der AR-Ansicht wird das Lebensmittel zur Einkaufsliste hinzugefügt. Diese kann zum stationären
                        Einkauf genutzt werden, man kann direkt aus der App heraus einen Online Kauf durchführen oder
                        die Liste mit Freunden teilen.
                    </p>

                    <h4>Kernfunktionen</h4>
                    <ul>
                        <li>Scanne Volltext nach Lebensmitteln in nahezu Echtzeit</li>
                        <li>Scanne Mengen und Maßeinheiten in zwei Sprachen</li>
                        <li>Erkannte Lebensmittel werden in der Augmented Reality Ansicht direkt neben dem
                            zugehörigen Textabschnitt angezeigt
                        </li>
                        <li>Produkte können zur Einkaufsliste hinzugefügt werden, indem sie in der Augmented Reality
                            Ansicht angetippt werden
                        </li>
                        <li>Produkte können manuell zur Liste hinzugefügt werden, indem man das + Icon in der
                            Einkaufslisten-Ansicht berührt
                        </li>
                        <li>Setze den Scanner zurück indem Du das Gerät schüttelst (die erkannten Lebensmittel
                            verschwinden)
                        </li>
                    </ul>

                    <h4>Zusatzfunktionen</h4>
                    <ul>
                        <li>Wähle zwischen zwei AR Tag-Stylings rot und schwarz (standardmäßig rot)</li>
                        <li>Aktiviere Erkennung von Mengen und Maßeinheiten (standardmäßig aktiviert)</li>
                        <li>Teile Deine Einkaufsliste mit Freunden und Bekannten per SMS, Mail, WhatsApp, Facebook,
                            Twitter etc.
                        </li>
                        <li>Kaufe die Lebensmittel Deiner Einkaufsliste direkt im REWE Online Shop, indem Du auf den
                            Warenkorb in der Einkaufslisten-Ansicht drückst (es findet eine Weiterleitung zum Safari
                            Webbrowser statt in welchem die Kaufabwicklung durchgeführt werden kann)
                        </li>
                    </ul>

                    <h4>Einstellungen</h4>
                    <ul>
                        <li>Wähle zwischen zwei Stylings klassisch rot und einfach schwarz (standardmäßig rot)</li>
                        <li>Aktiviere Erkennung von Mengen und Maßeinheiten (standardmäßig aktiviert)</li>
                        <li>Aktiviere visuelles Feedback für die Erkennung der Textregionen (standardmäßig
                            deaktiviert)
                        </li>
                        <li>Wähle eine der unterstützten Sprachen (Englisch und Deutsch) für die optische
                            Zeichenerkennung
                        </li>
                    </ul>

                    <p>
                        * Die App kann keine handgeschriebenen Texte analysieren.<br/><br/>

                        <br/>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://itunes.apple.com/de/app/cunum_grocerylist/id1329215706?mt=8">
                            <img width="135" height="40" src="../../img/appstore_badge.svg" alt="iOS Appstore Badge"/>
                        </a>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://play.google.com/store/apps/details?id=com.cunum.grocerylistar">
                            <img height="60" src="../../img/google_play_badge.png" alt="Google Play Store Badge"/>
                        </a>
                    </p>

                    <div className="app-spacer">
                        <h2 id="ninewatch">Nine Watch (Apple Watch)</h2>
                    </div>

                    <p>Nutze die beliebte 9GAG App nun auch auf Deiner Apple Watch, mit der Third Party App Nine Watch.
                        Durchstöbere die populären Hot, Fresh und Trending Posts von 9GAG direkt auf Deiner Smartwatch,
                        oder schaue Dir die Bilder, Gifs, Memes und Videos Deiner präferierten 9GAG Kategorien an. Bitte
                        beachte, die App bietet keine Unterstützung für 9GAG auf dem iPhone oder dem iPad, hierfür
                        solltest Du die exzellente offizielle 9GAG App verwenden!
                    </p>

                    <h4>Kernfunktionen</h4>
                    <ul>
                        <li>Schaue Dir lustige Bilder, Gifs, Memes und Videos direkt auf Deiner Apple Watch an</li>
                        <li>Stöbere in den Rubriken Hot, Fresh und Trending nach den populärsten 9GAG Posts</li>
                        <li>Filtere die Posts nach den für Dich relevanten Kategorien</li>
                    </ul>

                    <h4>Zusatzfunktionen</h4>
                    <ul>
                        <li>Schaue Dir auf der Detailansicht die Anzahl der Upvotes und Kommentare des Posts an</li>
                    </ul>

                    <div className="app-spacer">
                        <h2 id="pickupnotify">Pickup Notify for Apple Store (Apple Watch)</h2>
                    </div>

                    <p>You want to get an Apple product as fast as possible, without much of a hassle? Use the Apple
                        Store Pickup Notifier for Apple Watch!<br/>

                        It will notify you on your Apple Watch whenever a product is available for pickup in your local
                        Apple store, so
                        you can immediately place a reservation online.

                        How does it work?
                    </p>
                    <ul>
                        <li>First you have to configure the notifier on your iPad or iPhone:
                            <ul>
                                <li>Switch "Activate background notifications" on (no periodically checks if off!)
                                </li>
                                <li>Input your postal code (the nearest Apple store will be determined)</li>
                                <li>Input one or more product part numbers of the Apple products you want to get
                                    notifications for,
                                    you can find these online
                                </li>
                                <li>Press "Add product to notification list" for each product id you did input</li>
                            </ul>
                        </li>
                        <li>Press "Synchronize with Apple Watch" to configure your Apple Watch to notify you when
                            the products are
                            available for pickup in your local Apple Store
                        </li>
                        <li>Open your Apple Watch app, the products you configured should now appear with the
                            current pickup status
                        </li>
                        <li>Done, you can close the Watch app, the watch will now periodically check in the
                            background for pickup
                            availability, just wait for the notification, or do manual realtime updates in the Watch
                            app by tapping on
                            the status image
                        </li>
                    </ul>

                    <h4>Kernfunktionen</h4>
                    <ul>
                        <li>Füge Apple Produkte zu Deiner Pickup Watchlist hinzu</li>
                        <li>Realtime Status Abfrage ob eine Abholung im Apple Store verfügbar ist</li>
                        <li>Automatische Notifications wenn ein Produkt zur Abholung im gewählten Apple Store
                            verfügbar ist
                        </li>
                    </ul>

                </div>
            </div>
        );
    }
}

export default MobileApps;
