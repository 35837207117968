import React, { Component } from 'react';

import GaugeChart from 'react-gauge-chart'

class Widget extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: props.value || "",
            title: props.title || "",
            subtitle: props.subtitle || "",
            subtitle2: props.subtitle2 || "",
            updated: props.updated || "",
            meter: props.meter || false,
            displayValue: props.displayValue || "",
            background: props.background,
            greenThreshold: props.greenThreshold,
            yellowThreshold: props.yellowThreshold
        }
    }

    componentWillReceiveProps(nextProps) {
        var update = {}
        for (const [key, value] of Object.entries(nextProps)) {
            if (value != this.state[key]) {
                update[key] = value
            }
        }
        if (Object.keys(update).length !== 0) {
            this.setState(update)
        }
    }

    getBackgroundColor() {
        if (this.state.value <= this.state.greenThreshold) {
            return "green-background"
        }
        else if (this.state.value <= this.state.yellowThreshold) {
            return "yellow-background"
        }
        else if (this.state.greenThreshold === 0 && this.state.value === 100) {
            return "black-background"
        }
        else {
            return "red-background"
        }
    }

    render() {
        return (
            <div className={"dashboard-widget " + (this.state.background || this.getBackgroundColor())}>
                <div className="dashboard-widget-title">{this.state.title}</div>
                {!this.state.displayValue && !this.state.value && (
                    <div className="spinner">
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                )}
                {this.state.meter && this.state.value && (
                    <GaugeChart
                        className="dashboard-widget-meter"
                        id={"gauge-chart" + this.state.title}
                        style={{height: "140px", width: "100%"}}
                        nrOfLevels={20}
                        percent={this.state.value}
                        formatTextValue={val => this.state.displayValue || this.state.value}
                    />
                )}
                {!this.state.meter && (<div className="dashboard-widget-value">{this.state.displayValue || this.state.value}</div>)}
                <div className="dashboard-widget-subtitle">{this.state.subtitle}<br/>{this.state.subtitle2}</div>
                <div className="dashboard-widget-updated">{this.state.updated}</div>
            </div>
        );
    }
}

export default Widget;
