import React, {Component} from 'react';

class MyComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stake: "",
            commission: "1%",
            delegators: "",
            chunks: "",
            blocks: "",
            public_key: "",
            account_id: ""
        };
        this.loadPoolData();
    }

    rpcFetch(environment, method, params = [null]) {
        return fetch("https://rpc." + environment + ".near.org", {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json'
           },
           body: JSON.stringify({
               "jsonrpc": "2.0",
               "id": "dontcare",
               "method": method,
               "params": params
           })
        })
        .then((response) => response.json())
    }

    loadPoolData = () => {
        this.rpcFetch("mainnet", "validators")
            .then((data) => {
                const validator = data.result.current_validators.find((validator) => validator.account_id === "cunum.poolv1.near")
                if (validator.length == 0) {
                    return
                }
                this.setState({
                    public_key: validator.public_key,
                    account_id: validator.account_id,
                    stake: this.formatStake(validator.stake) + " NEAR",
                    chunks: validator.num_produced_chunks + "/" + validator.num_expected_chunks,
                    blocks: validator.num_produced_blocks + "/" + validator.num_expected_blocks
                })
            })
            .catch((e) => { console.error(e) })

        this.rpcFetch("mainnet", "query", {
                    "request_type": "call_function",
                    "finality": "final",
                    "account_id": "cunum.poolv1.near",
                    "method_name": "get_number_of_accounts",
                    "args_base64": btoa(JSON.stringify({
                        "from_index": 0,
                        "limit": 100000
                    }))
                })
                .then((data) => {
                    const delegatorCount = JSON.parse(String.fromCharCode(...data.result.result))
                    this.setState({
                        delegators: delegatorCount,
                    })
                })
                .catch((error) => console.error(error))
    }

    formatStake(stake) {
        return Math.round(stake / 1000000000000000000000000)
    }

    lovelaceToAda(input, decimals = 2) {
        const splitUnitValue = input.split(" ");
        if (splitUnitValue && splitUnitValue.length > 1) {
            return parseFloat(splitUnitValue[0] / 1000000).toFixed(decimals) + " " + splitUnitValue[1];
        }
        return "";
    }

    render() {
        return (
            <div>
                <h4>Pool Stats</h4>
                <table className="pool-stats-table">
                    <tbody>
                        <tr>
                            <td>Pool ID</td>
                            <td>
                                <a style={{marginLeft: "0px"}}
                                   target="_blank"
                                   rel="nofollow noopener noreferrer"
                                   href={"https://nearscope.net/validator/" + this.state.account_id}>{this.state.account_id}</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Public Key</td>
                            <td>
                                {this.state.public_key}
                            </td>
                        </tr>
                        <tr>
                            <td>Stake</td>
                            <td>{this.state.stake}</td>
                        </tr>
                        <tr>
                            <td>Delegators</td>
                            <td>{this.state.delegators}</td>
                        </tr>
                        <tr>
                            <td>Commission</td>
                            <td>{this.state.commission}</td>
                        </tr>
                        <tr>
                            <td>Blocks</td>
                            <td>{this.state.blocks}</td>
                        </tr>
                        <tr>
                            <td>Chunks</td>
                            <td>{this.state.chunks}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default MyComponent;
