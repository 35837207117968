import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { withRouter } from "react-router";
import * as serviceWorker from './serviceWorker';
import CunumApi from "./components/api/App";
import CunumNear from "./components/near/App";
import CunumCosmos from "./components/cosmos/App";
import CunumApp from "./components/App";

import './Cunum.css';

const App = withRouter(props => {
    if (window.location.hostname.indexOf("api.cunum") !== -1) {
        return <CunumApi {...props} />
    } else if (window.location.hostname.indexOf("near.cunum") !== -1) {
        return <CunumNear {...props} />
    }  else if (window.location.hostname.indexOf("cosmos.cunum") !== -1) {
        return <CunumCosmos {...props} />
    } else {
        return <CunumApp {...props} />;
    }
});

ReactDOM.render(<BrowserRouter><App/></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
