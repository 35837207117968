import React, { Component } from 'react';

class StickyNotes extends Component {

    render() {
        return (
            <div>
                <div class="jumbotron main-teaser">
                    <div class="container no-padding-top">
                        <h1 class="display-3">End User License Agreement</h1>
                        <p>StickyNotes AR App</p>
                    </div>
                </div>

                <div class="container half-padding-top">

                    <p>This End User License Agreement (“Agreement”) is between you and StickyNotes AR and governs use of this app made
                        available through the Apple App Store. By installing the StickyNotes AR App, you agree to be bound by this
                        Agreement and understand that there is no tolerance for objectionable content. If you do not agree with the
                        terms and conditions of this Agreement, you are not entitled to use the StickyNotes AR App.

                        In order to ensure StickyNotes AR provides the best experience possible for everyone, we strongly enforce a no
                        tolerance policy for objectionable content. If you see inappropriate content, please report by swiping left on
                        the post and selecting "report".
                    </p>
                    <p><br/>
                        <strong>1. Parties</strong><br/>

                        This Agreement is between you and StickyNotes AR only, and not Apple, Inc. (“Apple”). Notwithstanding the
                        foregoing, you acknowledge that Apple and its subsidiaries are third party beneficiaries of this Agreement and
                        Apple has the right to enforce this Agreement against you. StickyNotes AR, not Apple, is solely responsible for
                        the StickyNotes AR App and its content.
                    </p>
                    <p><br/>
                        <strong>2. Privacy</strong><br/>

                        StickyNotes AR may collect and use information about your usage of the StickyNotes AR App, including certain
                        types of information from and about your device. StickyNotes AR may use this information, as long as it is in a
                        form that does not personally identify you, to measure the use and performance of the StickyNotes AR App.
                    </p>
                    <p><br/>
                        <strong>3. Limited License</strong><br/>

                        StickyNotes AR grants you a limited, non-exclusive, non-transferable, revocable license to use the StickyNotes AR
                        App for your personal, non-commercial purposes. You may only use the StickyNotes AR App on Apple devices that
                        you own or control and as permitted by the App Store Terms of Service.
                    </p>
                    <p><br/>
                        <strong> 4. Age Restrictions</strong><br/>

                        By using the StickyNotes AR App, you represent and warrant that (a) you are 17 years of age or older and you
                        agree to be bound by this Agreement; (b) if you are under 17 years of age, you have obtained verifiable consent
                        from a parent or legal guardian; and (c) your use of the StickyNotes AR App does not violate any applicable law
                        or regulation. Your access to the StickyNotes AR App may be terminated without warning if StickyNotes AR
                        believes, in its sole discretion, that you are under the age of 17 years and have not obtained verifiable
                        consent from a parent or legal guardian. If you are a parent or legal guardian and you provide your consent to
                        your child’s use of the StickyNotes AR App, you agree to be bound by this Agreement in respect to your child’s
                        use of the StickyNotes AR App.
                    </p>
                    <p><br/>
                        <strong>5. Objectionable Content Policy</strong><br/>

                        Content may not be submitted to StickyNotes AR, who will moderate all content and ultimately decide whether or
                        not to post a submission to the extent such content includes, is in conjunction with, or alongside any,
                        Objectionable Content. Objectionable Content includes, but is not limited to: (i) sexually explicit materials;
                        (ii) obscene, defamatory, libelous, slanderous, violent and/or unlawful content or profanity; (iii) content that
                        infringes upon the rights of any third party, including copyright, trademark, privacy, publicity or other
                        personal or proprietary right, or that is deceptive or fraudulent; (iv) content that promotes the use or sale of
                        illegal or regulated substances, tobacco products, ammunition and/or firearms; and (v) gambling, including
                        without limitation, any online casino, sports books, bingo or poker.
                    </p>
                    <p><br/>
                        <strong>6. Warranty</strong><br/>

                        StickyNotes AR disclaims all warranties about the StickyNotes AR App to the fullest extent permitted by law. To
                        the extent any warranty exists under law that cannot be disclaimed, StickyNotes AR, not Apple, shall be solely
                        responsible for such warranty.
                    </p>
                    <p><br/>
                        <strong>7. Maintenance and Support</strong><br/>

                        StickyNotes AR does provide minimal maintenance or support for it but not to the extent that any maintenance or
                        support is required by applicable law, StickyNotes AR, not Apple, shall be obligated to furnish any such
                        maintenance or support.
                    </p>
                    <p><br/>
                        <strong>8. Product Claims</strong><br/>

                        StickyNotes AR, not Apple, is responsible for addressing any claims by you relating to the StickyNotes AR App or
                        use of it, including, but not limited to: (i) any product liability claim; (ii) any claim that the StickyNotes
                        AR App fails to conform to any applicable legal or regulatory requirement; and (iii) any claim arising under
                        consumer protection or similar legislation. Nothing in this Agreement shall be deemed an admission that you may
                        have such claims.
                    </p>
                    <p><br/>
                        <strong>9. Third Party Intellectual Property Claims</strong><br/>

                        StickyNotes AR shall not be obligated to indemnify or defend you with respect to any third party claim arising
                        out or relating to the StickyNotes AR App. To the extent StickyNotes AR is required to provide indemnification
                        by applicable law, StickyNotes AR, not Apple, shall be solely responsible for the investigation, defense,
                        settlement and discharge of any claim that the StickyNotes AR App or your use of it infringes any third party
                        intellectual property rights.
                    </p>

                </div>
            </div>
        );
    }
}

export default StickyNotes;
