import React, { Component } from 'react';

class Home extends Component {

    render() {
        return (
            <div className="content near-background">
                <div className="container half-padding-top">
                    <div className="center-main">
                        <img src="/img/near_icon_white.svg" width="150" height="150"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
