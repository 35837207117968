import React, {Component} from 'react';

class MobileApps extends Component {

    render() {
        return (
            <div>
                <div className="jumbotron main-teaser">
                    <div className="container no-padding-top">
                        <h1 className="display-3">Games</h1>
                        <p>Hier finden Sie die Dokumentation aller momentan verfügbaren Spiele</p>
                    </div>
                </div>

                <div className="container half-padding-top">

                    <div className="app-spacer mobile-app-info">
                        <h2 id="hibiscus_red">Hibiscus Red | Part 2</h2>
                    </div>
                    <p>In the second part of the series, Hibiscus explores the other world in search of his father. As
                        the plot thickens, Hibiscus faces old enemies and makes new friends who help him on his way.
                    </p>

                    <img src="../../img/hibiscus_red_part2_screen1.jpg" alt="Hibiscus Red | Part 2 screenshot 1"
                         width="50%"/>
                    <img src="../../img/hibiscus_red_part2_screen2.jpg" alt="Hibiscus Red | Part 2 screenshot 2"
                         width="50%"/>

                    <br/>
                    <br/>

                    <h4>Core features</h4>
                    <ul>
                        <li>Almost classic point and click adventure game</li>
                        <li>Part 2 of 4 with around 1+ hours of gameplay</li>
                        <li>Multi-language support: English and german texts</li>
                        <li>Text-to-speech option for english language</li>
                        <li>No hint mechanics, ergo no casual game</li>
                    </ul>

                    <br/>

                    <p>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://itunes.apple.com/de/app/hibiscusred/id1496531586?mt=8">
                            <img className="game-badge-image" width="135" height="40" src="../../img/platform_ios.svg"
                                 alt="iOS Appstore Badge"/>
                        </a>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://play.google.com/store/apps/details?id=com.cunum.hibiscusred.p2">
                            <img className="game-badge-image" width="135" height="40"
                                 src="../../img/platform_android.svg" alt="Google Play Store Badge"/>
                        </a>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://cunum.itch.io/hibiscus-red-part-two">
                            <img className="game-badge-image" width="135" height="40" src="../../img/itch_badge.svg"
                                 alt="Itch IO Badge"/>
                        </a>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://store.steampowered.com/app/1175970/Hibiscus_Red">
                            <img className="game-badge-image" width="135" height="40" src="../../img/steam_badge.svg"
                                 alt="Steam Badge"/>
                        </a>
                    </p>

                </div>

                <div className="container half-padding-top">

                    <div className="app-spacer mobile-app-info">
                        <h2 id="long_count">Long Count | Part 1</h2>
                    </div>
                    <p>Embark on a journey to unravel a long lost mystery, explore the old town of the beautiful city of
                        Heidelberg, Germany in this interactive novel.
                    </p>

                    <img src="../../img/long_count_screen2.jpg" alt="Long Count screenshot 1" width="50%"/>
                    <img src="../../img/long_count_screen1.jpg" alt="Long Count screenshot 2" width="50%"/>

                    <br/>
                    <br/>

                    <h4>Core features</h4>
                    <ul>
                        <li>Sierra-style classic Point & Click Adventure Game</li>
                        <li>Day 1 to 3 (of 9 in total) with an estimated 4 hours of gameplay</li>
                        <li>Text-to-speech option for english language</li>
                        <li>No hint mechanics, this version is no casual adventure game</li>
                    </ul>

                    <br/>

                    <p>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://itunes.apple.com/de/app/longcount/id1488333010?mt=8">
                            <img className="game-badge-image" width="135" height="40" src="../../img/platform_ios.svg"
                                 alt="iOS Appstore Badge"/>
                        </a>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://play.google.com/store/apps/details?id=com.cunum.longcount">
                            <img className="game-badge-image" width="135" height="40"
                                 src="../../img/platform_android.svg" alt="Google Play Store Badge"/>
                        </a>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://cunum.itch.io/long-count">
                            <img className="game-badge-image" width="135" height="40" src="../../img/itch_badge.svg"
                                 alt="Itch IO Badge"/>
                        </a>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://store.steampowered.com/app/1190270/Long_Count">
                            <img className="game-badge-image" width="135" height="40" src="../../img/steam_badge.svg"
                                 alt="Steam Badge"/>
                        </a>
                    </p>

                </div>

                <div className="container half-padding-top">

                    <div className="app-spacer mobile-app-info">
                        <h2 id="hibiscus_red">Hibiscus Red | Part 1</h2>
                    </div>
                    <p>In the first part of this new adventure game series Hibiscus discovers the shocking truth about
                        his father and sets out on a journey to save his family and also the world. Or is it worlds?
                    </p>

                    <img src="../../img/hibiscus_red_screen1.jpg" alt="Hibiscus Red screenshot 1" width="50%"/>
                    <img src="../../img/hibiscus_red_screen2.jpg" alt="Hibiscus Red screenshot 2" width="50%"/>

                    <br/>
                    <br/>

                    <h4>Core features</h4>
                    <ul>
                        <li>Almost classic point and click adventure game</li>
                        <li>Part 1 of 4 with around 1+ hours of gameplay</li>
                        <li>Multi-language support: English and german texts</li>
                        <li>Text-to-speech option for english language (I know this sounds really bad at times, you can
                            switch it off in the options if it bothers you :D)
                        </li>
                    </ul>

                    <br/>

                    <p>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://itunes.apple.com/de/app/hibiscusred/id1480285343?mt=8">
                            <img className="game-badge-image" width="135" height="40" src="../../img/platform_ios.svg"
                                 alt="iOS Appstore Badge"/>
                        </a>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://play.google.com/store/apps/details?id=com.cunum.hibiscusred">
                            <img className="game-badge-image" width="135" height="40"
                                 src="../../img/platform_android.svg" alt="Google Play Store Badge"/>
                        </a>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://cunum.itch.io/hibiscus-red">
                            <img className="game-badge-image" width="135" height="40" src="../../img/itch_badge.svg"
                                 alt="Itch IO Badge"/>
                        </a>
                        <a target="_blank"
                           className="badge-link"
                           rel="nofollow noopener noreferrer"
                           href="https://store.steampowered.com/app/1175970/Hibiscus_Red">
                            <img className="game-badge-image" width="135" height="40" src="../../img/steam_badge.svg"
                                 alt="Steam Badge"/>
                        </a>
                    </p>

                </div>
            </div>
        );
    }
}

export default MobileApps;
