import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <div>
                <div className="jumbotron main-teaser-background no-padding-top">
                    <div className="container no-padding-top">
                        <h1 className="display-3" style={{color: "black"}}>404 not found</h1>
                    </div>
                </div>
                <div className="container">
                </div>
            </div>
        );
    }
}

export default Home;
