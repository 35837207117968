import React, { Component } from 'react';

import Widget from "./widgets/Widget";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

class MyComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            stats: {},
            environment: "mainnet"
        }
    }

    componentDidMount() {
        toast.info("Loading epoch ranking, please wait...")
        this.updateValidators()
        this.updateValidatorsInterval = setInterval(this.updateValidators, 5 * 60 * 1000)
    }

    componentWillUnmount() {
        clearInterval(this.updateValidatorsInterval)
    }

    updateValidators() {
        fetch("https://rpc." + this.state.environment + ".near.org", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jsonrpc": "2.0",
                "id": "dontcare",
                "method": "validators",
                "params": [null]
            })
        })
        .then((response) => response.json())
        .then((data) => {
            const validators = data.result.current_validators
            const epoch = data.result.epoch_height
            let result = []
            let sumUptime = 0
            let green = 0
            let yellow = 0
            let red = 0
            let zero = 0
            let sixty = 0
            let seventy = 0
            let eighty = 0
            for (let validator of validators) {
                const id = validator.account_id.split(".")[0]
                const blocksProduced = parseFloat(validator.num_produced_blocks)
                const blocksExpected = parseFloat(validator.num_expected_blocks)
                const chunksProduced = parseFloat(validator.num_produced_chunks)
                const chunksExpected = parseFloat(validator.num_expected_chunks)
                const stake = Math.round(validator.stake / 1000000000000000000000000)
                const expectedTotal = blocksExpected + chunksExpected
                const producedTotal = blocksProduced + chunksProduced
                const uptime = parseFloat(expectedTotal === 0 ? 100 : Math.round(producedTotal * 100 / expectedTotal * 100) / 100)
                sumUptime += uptime
                result.push({
                    title: id,
                    subtitle: "Chunks " + chunksProduced + "/" + chunksExpected,
                    subtitle2: "Blocks " + blocksProduced + "/" + blocksExpected,
                    displayValue: uptime + "%",
                    value: (100 - uptime),
                    greenThreshold: 0,
                    yellowThreshold: 10
                })
                if (uptime == 100) {
                    green += 1
                }
                else if (uptime >= 80) {
                    yellow += 1
                }
                else if (uptime == 0) {
                    zero += 1
                }
                else {
                    red += 1
                }
            }

            result = result.sort((a, b) => a.value - b.value || a.title.localeCompare(b.title))
            const stats = { epoch, green, yellow, red, zero, averageUptime: (Math.round(sumUptime / result.length * 100) / 100) + "%" }
            this.setState({ data: result, stats })
        })
        .catch((e) => {
            console.error(e)
        })
    }

    onChangeEnvironment(environment) {
        this.setState({environment}, () => this.updateValidators())
    }

    render() {
        return (
            <div className="content">
                <div className="jumbotron-small">
                    <div className="container">
                        <div className="dashboard-header-title">
                            <div className="dashboard-logo"></div>
                            <div className="dashboard-header-title-text">Epoch Uptime</div>
                        </div>
                    </div>
                    <div className="dashboard-header-analytics">
                        <div className="dashboard-header-analytics-environment">
                            <div className="dashboard-form">
                                <label htmlFor="environment">Environment</label>
                                <select id="environment" value={this.state.environment} onChange={(e) => this.onChangeEnvironment(e.target.value)}>
                                    <option value="mainnet">mainnet</option>
                                    <option value="testnet">testnet</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="half-padding-top">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-3">
                            <span className="dashboard-analytics-statistics-epoch">
                                <ul>
                                    <li>Epoch {this.state.stats.epoch}</li>
                                    <li style={{fontSize:"16px"}}>Average Uptime: {this.state.stats.averageUptime}</li>
                                </ul>
                            </span>
                        </div>
                        <div className="col-sm">
                          <div>
                              <table className="table table-sm" style={{width:"700px",margin:"30px auto"}}>
                                <thead>
                                  <tr>
                                      <th>Uptime</th>
                                      <th>0%</th>
                                      <th>1-79%</th>
                                      <th>80-99%</th>
                                      <th>100%</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                      <td><strong>Count</strong></td>
                                      <td>{this.state.stats.zero}</td>
                                      <td>{this.state.stats.red}</td>
                                      <td>{this.state.stats.yellow}</td>
                                      <td>{this.state.stats.green}</td>
                                  </tr>
                                </tbody>
                              </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    { this.state.data.length === 0 && (
                        <div className="spinner">
                          <div className="lds-ring-black"><div></div><div></div><div></div><div></div></div>
                          <div className="loading-text">Loading...</div>
                        </div>
                    )}
                    { this.state.data.map((widget, widgetIndex) => {
                        return <Widget
                           key={widgetIndex}
                           value={widget.value}
                           title={widget.title}
                           subtitle={widget.subtitle}
                           subtitle2={widget.subtitle2}
                           updated={widget.updated}
                           meter={widget.meter}
                           displayValue={widget.displayValue}
                           greenThreshold={widget.greenThreshold}
                           yellowThreshold={widget.yellowThreshold}
                        />
                      })
                    }
                </div>
            </div>
        );
    }
}

export default MyComponent;
