import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Header extends Component {
    render() {
        return (
            <header className="app-header">
                <nav className="navbar navbar-expand-lg navbar-dark">
                    <Link className="" to='/'>
                        <img src="../../img/cunum.svg" width="200" height="50" alt="Cunum logo"/>
                    </Link>
                    <div className="near-menu">
                        <Link className="" to='/monitoring'>
                            Monitoring
                        </Link>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Header;
