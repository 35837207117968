import React, { Component } from 'react';

import AdaPoolWidget from "./AdaPoolWidget";
import NearPoolWidget from "./near/NearPoolWidget";

class Crypto extends Component {

    render() {
        return (
            <div>
                <div className="jumbotron main-teaser">
                    <div className="container no-padding-top">
                        <h1 className="display-3">Crypto</h1>
                        <p>Here you can find the documentation of all currently available Crypto Stake Pools</p>
                    </div>
                </div>

                <div className="container half-padding-top">

                    <div className="row">
                        <div className="col-md-6">
                            <h2>Near</h2>
                            <p><img src="../../img/near_icon.svg" width="100" className="coin-icon" alt="Near"/>
                                Crypto Stake Pool for the Near Blockchain running 24/7 on German servers. Be part of the Near community by staking with Cunum Stakepool. <a href="#near" role="button">More Info &raquo;</a>
                            </p>
                        </div>
                    </div>

                    <div className="app-spacer mobile-app-info">
                        <h2 id="near">Near (NEAR)</h2>
                    </div>
                    <h4>Near</h4>
                    <p>NEAR Protocol is a layer-one blockchain that was designed as a community-run cloud computing platform and that eliminates some of the limitations that have been bogging competing blockchains, such as low transaction speeds, low throughput and poor interoperability. This provides the ideal environment for DApps and creates a developer and user-friendly platform. For instance, NEAR uses human-readable account names, unlike the cryptographic wallet addresses common to Ethereum. NEAR also introduces unique solutions to scaling problems and has its own consensus mechanism called “Doomslug.”
                    </p>

                    <h4>Why this pool?</h4>
                    <ul>
                        <li>Only 1% service fee, the highest network APY</li>
                        <li>High performance node always online located in Germany</li>
                        <li>24/7 service</li>
                        <li>Global network monitoring</li>
                        <li>Maximum compounding rates for delegates because of self ping calls every 4 hours</li>
                    </ul>

                    <NearPoolWidget/>

                    </div>
            </div>
        );
    }
}

export default Crypto;
