import React, { Component } from 'react';

class OpenSource extends Component {

    render() {
        return (
            <div>
                <div className="jumbotron main-teaser">
                    <div className="container no-padding-top">
                        <h1 className="display-3">Open Source Projekte</h1>
                        <p>Hier finden Sie die Dokumentation aller Open Source Projekte</p>
                    </div>
                </div>

                <div className="container">
                    <div className="app-spacer">
                        <h2 id="word2vec-visualizer">Word2Vec Visualizer</h2>
                    </div>
                    <div className="badges">
                        <img src="https://travis-ci.org/cunum/word2vec-visualizer.svg?branch=master" alt="Build status"/>
                        <img src="https://img.shields.io/docker/build/cunum/word2vec-visualizer.svg" alt="Docker build status"/>
                        <img src="https://img.shields.io/node/v/latest-version.svg?style=flat" alt="Node version"/>
                    </div>
                    <br/>
                    <p>This tool helps you visualize, query and explore Word2Vec models. Word2Vec is a deep learning technique that feeds massive amounts of text into a shallow neural net which can then be used to solve a variety of NLP and ML problems.                         Word2Vec Visualizer uses Gensim to list and compare vectors and it uses t-SNE to visualize a dimensional reduction of the vector space. Scikit-Learn is used for K-Means clustering.
                        <br/><br/>
                        <img width="100%" height="auto" src="https://github.com/cunum/word2vec-explorer/raw/master/public/screen.png?raw=true" alt="word2vec visualizer screen"/>
                        <br/>
                    </p>
                    <h5 className="half-padding-top">Setup</h5>
                    <p>Just use the pre-built docker image on docker hub cunum/word2vec-visualizer and directly jump to step Usage or clone the project and build the docker image yourself by running in project directory</p>
                    <div className="code">
                        docker build . -t cunum/word2vec-visualizer
                    </div>
                    <br/>
                    <h5>Usage</h5>
                    <p>
                    <span>A) Run with a pre-trained model</span>
                    </p>
                    <div className="code">
                        docker run -p 8080:8080 -v /path/to/word2vec.model:/word2vec.model cunum/word2vec-visualizer
                    </div>
                    <p>
                        <br/>
                    <span>B) Run with text documents (used to train a model on startup)</span>
                    </p>
                    <div className="code">
                    docker run -p 8080:8080 -v /path/to/documents:/documents cunum/word2vec-visualizer
                    </div>
                    <p>
                    <br/>Now point your browser at <a href="http://localhost:8080">http://localhost:8080</a> to load the explorer
                    </p>
                    <br/>
                    <h5 className="half-padding-top">Obtaining pre-trained models</h5>
                    <p>A classic example of Word2Vec is the Google News model trained on 600M sentences<br/></p>
                    <ul>
                        <li><a href="https://drive.google.com/file/d/0B7XkCwpI5KDYNlNUTTlSS21pQmM/edit?usp=sharing">GoogleNews-vectors-negative300.bin.gz</a></li>
                        <li><a href="https://github.com/3Top/word2vec-api#where-to-get-a-pretrained-models">More models</a></li>
                    </ul>
                    <br/>
                    <h5 className="half-padding-top">Show me the code</h5>
                    <br/>
                    <p>
                        <a target="_blank" rel="noopener noreferrer nofollow" href="https://github.com/cunum/word2vec-visualizer">
                            <img width="200" height="82" src="../../img/github.svg" alt="Github word2vec-visualizer "/>
                        </a>
                    </p>
                </div>
            </div>
        );
    }
}

export default OpenSource;
