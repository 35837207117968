import React, { Component } from 'react';
import { Switch } from "react-router";
import { Route } from "react-router-dom";

import Header from './Header';
import Home from "./Home";
import Footer from "./Footer";
import NotFound from "../NotFound";
import Monitoring from "./Monitoring";
import Analytics from "./Analytics";
import Tools from "./Tools";
import Delegation from "./Delegation";
import NetworkScan from "./NetworkScan";
import EpochUptime from "./EpochUptime";
import ValidatorHistory from "./ValidatorHistory";
import { ToastContainer } from "react-toastify"

import '../../Cunum.css';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {

    render() {
        return (
            <div className="App">
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Header/>
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/monitoring' component={Monitoring}/>
                    <Route exact path='/analytics' component={Analytics}/>
                    <Route exact path='/tools' component={Tools}/>
                    <Route exact path='/tools/delegation' component={Delegation}/>
                    <Route exact path='/tools/network-scan' component={NetworkScan}/>
                    <Route exact path='/analytics/epoch-ranking' component={EpochUptime}/>
                    <Route exact path='/analytics/validator-history' component={ValidatorHistory}/>
                    <Route component={NotFound}/>
                </Switch>
                <Footer/>
            </div>
        );
    }
}

export default App;