import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return !window.location.pathname.includes("cardanotrees") && (
            <footer className="footer" style={{marginLeft: "50px"}}>
                <div className="footer-text">
                    <p>Copyright &copy; 2016-2022 Christian Mahler</p>
                </div>
            </footer>
        );
    }
}

export default Footer;
