import React, { Component } from 'react';

import Widget from "./widgets/Widget";
import { Link } from "react-router-dom";

class MyComponent extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="content">
                <div className="jumbotron-small">
                    <div className="container">
                        <div className="dashboard-header-title">
                            <div className="dashboard-logo"></div>
                            <div className="dashboard-header-title-text">Validator Tools</div>
                        </div>
                    </div>
                </div>
                <div className="half-padding-top">
                    <Link to='/tools/delegation'>
                        <div className="link-bubble" style={{textDecoration: "none"}}>
                            Validator Delegation
                        </div>
                    </Link>
                    <Link to='/tools/network-scan'>
                        <div className="link-bubble" style={{textDecoration: "none"}}>
                            Validator Network Scan
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default MyComponent;
