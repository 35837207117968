import React, {Component} from 'react';

class CardanoTreeGalery extends Component {

    idList = []

    constructor(props) {
        super(props);

        let showAddressInput = true
        if (this.props.match.params.walletAddress) {
            this.address = this.props.match.params.walletAddress
            this.assetId = this.props.match.params.assetId || null
            this.search()
            showAddressInput = false
        }

        this.state = {
            treeList: [],
            showAddressInput: showAddressInput,
            showDetail: false,
            detailImage: null
        }

        this.singleImage.bind(this)
    }

    search() {
        fetch("https://pool.pm/wallet/" + this.address)
               .then(data => data.json())
               .then(json => {
                    const treeList = []
                    let detailImage = null
                    for (var token of json.tokens) {
                        if (token.policy == "e09e4f4217669b7f735b7a3724e835d8d6344db128eb03d6ea72885e") {
                            const image = token.metadata.files[0].src.join("")
                            treeList.push({
                                image,
                                name: token.metadata.name
                            })
                            if (token.metadata.name == this.assetId) {
                                detailImage = image
                            }
                        }
                    }
                    this.updateURL()
                    this.setState({
                        treeList: treeList,
                        showAddressInput: false,
                        showDetail: detailImage != null,
                        detailImage: detailImage
                    })
               })
               .catch((err) => { console.log(err) })
    }

    updateInput(e) {
        this.address = e.target.value
    }

    onFormSubmit(e) {
        e.preventDefault()
        this.search()
    }

    updateURL() {
        const assetPath = this.assetId == null ? "" : "/" + this.assetId
        this.props.history.push("/cardanotrees/galery/" + this.address + assetPath)
    }

    singleImage(image, name) {
        this.assetId = name
        this.updateURL()
        this.setState({
            showDetail: !this.state.showDetail,
            detailImage: image
        })
    }

    render() {
        return (
            <div className="content" style={{width: "100%"}}>
               <div className="container">
                  <div className="container half-padding-top">
                     {!this.state.showDetail && (
                    <div className="container row">
                        <h4 className="main-teaser-title" className="galery-title">CardanoTrees <span style={{color: "darkgray"}}>Galery</span></h4>
                        {this.state.showAddressInput && (
                            <form onSubmit={this.onFormSubmit.bind(this)}>
                               <div>
                               <input
                                  className="input-address"
                                  type="text"
                                  name="q"
                                  placeholder={"Paste wallet address here (addr1...)"}
                                  onChange={this.updateInput.bind(this)}
                                  id="query"
                                  autoComplete="off"/>
                                </div>
                            </form>
                        )}
                        </div>
                       )}
                  </div>
                  <div className="treeviewer">
                     {
                         Object.entries(this.state.treeList).map(tree => {
                         const image = tree[1].image
                         const name = tree[1].name
                         return !this.state.showDetail && (
                                  <div key={name} className="item">
                                      <div className="overlay" onClick={() => this.singleImage(image, name)}></div>
                                      <iframe src={image}></iframe>
                                  </div>
                         )})
                    }
                  {this.state.showDetail && (
                        <div className="item detail container v-spacer-double">
                          <div className="overlay" onClick={() => this.singleImage()}></div>
                          <iframe src={this.state.detailImage}></iframe>
                        </div>
                    )}
                  </div>
               </div>
            </div>
        );
    }
}

export default CardanoTreeGalery;
