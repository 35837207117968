import React, { Component } from 'react';

class MyComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mobileInputOpen: false,
        }
    }

    mobileConfigRollout() {
        const open = this.state.mobileInputOpen
        this.setState({ mobileInputOpen: !open })
    }

    render() {
        return (
            <div className={"jumbotron-small dashboard-input-mobile-head-" + (this.state.mobileInputOpen ? "rollout" : "default")}>
                <div className="container">
                    <div className="dashboard-header-title">
                        <div className="dashboard-logo"></div>
                        <div className="dashboard-header-title-text">{this.props.title}</div>
                    </div>
                    <div className="dashboard-input-mobile">
                        <img className="dashboard-input-menu-btn" src="/img/menu.svg" onClick={() => this.mobileConfigRollout()}/>
                        <div className="dashboard-input-mobile-rollout" style={{display: this.state.mobileInputOpen ? "block" : "none"}}>
                            {this.props.children}
                        </div>
                    </div>
                    <div className="dashboard-input">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default MyComponent;
