import React, { Component } from 'react';
import { Switch } from "react-router";
import { Route } from "react-router-dom";

import Header from './Header';
import Home from "./Home";
import Footer from "../Footer";
import NotFound from "../NotFound";

import '../../Cunum.css';

class App extends Component {

    render() {
        return (
            <div className="App">
                <Header/>
                <div className="content">
                    <Switch>
                        <Route exact path='/' component={Home}/>
                        <Route component={NotFound}/>
                    </Switch>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default App;