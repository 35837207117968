import React, { Component } from 'react';

import Widget from "./widgets/Widget";
import { Link } from "react-router-dom";

class Analytics extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: []
        }
    }

    componentDidMount() {

        this.updateValidators()
    }

    updateValidators() {
        const environment = "shardnet"
        fetch("https://rpc." + environment + ".near.org", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "jsonrpc": "2.0",
                "id": "dontcare",
                "method": "validators",
                "params": [null]
            })
        })
        .then((response) => response.json())
        .then((data) => {
            const validators = data.result.current_validators
            let result = []
            for (let validator of validators) {
                const id = validator.account_id.split(".")[0]
                const blocksProduced = parseFloat(validator.num_produced_blocks)
                const blocksExpected = parseFloat(validator.num_expected_blocks)
                const chunksProduced = parseFloat(validator.num_produced_chunks)
                const chunksExpected = parseFloat(validator.num_expected_chunks)
                const expectedTotal = blocksExpected + chunksExpected
                const producedTotal = blocksProduced + chunksProduced
                const uptime = parseFloat(expectedTotal === 0 ? 100 : Math.round(producedTotal * 100 / expectedTotal * 100) / 100)
                result.push({
                    title: id,
                    subtitle: blocksProduced + "/" + blocksExpected + " " + chunksProduced + "/" + chunksExpected,
                    displayValue: uptime + "%",
                    value: (100 - uptime),
                    greenThreshold: 0,
                    yellowThreshold: 10
                })
            }
            result = result.sort((a, b) => a.value - b.value || a.title.localeCompare(b.title))
            result.forEach((r, index) => { r.subtitle = (index + 1) + "/" + result.length })
            this.setState({ data: result })
        })
        .catch((e) => {
            console.error(e)
        })
    }

    render() {
        return (
            <div className="content">
                <div className="jumbotron-small">
                    <div className="container">
                        <div className="dashboard-header-title">
                            <div className="dashboard-logo"></div>
                            <div className="dashboard-header-title-text">Validator Analytics</div>
                        </div>
                    </div>
                </div>
                <div className="half-padding-top">
                    <Link to='/analytics/validator-history'>
                        <div className="link-bubble">
                            Validator History
                        </div>
                    </Link>
                    <Link className="" to='/analytics/epoch-ranking'>
                        <div className="link-bubble">
                            Epoch Uptime
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Analytics;
